import type { ComponentType, ReactNode } from 'react';
import React, { Suspense, useCallback, useEffect } from 'react';
import { getCloudId, getHelpCenterAri } from 'util/meta';
import { isDefaultHelpCenter } from '@helpCenter/util/advanced-help-center/advanced-help-center';
import { getIsMultiHCEnabled } from '@helpCenter/util/env';
import { isVaRebrandToVsaEnabled } from 'feature-flags';
import { debounce } from 'lodash';
import { useIntl } from 'react-intl-next';
import { di } from 'react-magnetic-di';
import { useQueryLoader } from 'react-relay';
import { Loading } from 'view/common/loading';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import Heading from '@atlaskit/heading';
import Search from '@atlaskit/icon/glyph/search';
import { Box, Inline, Stack, Text, xcss } from '@atlaskit/primitives';
import Textfield from '@atlaskit/textfield';
import { USER_TYPING_DEBOUNCE_TIME } from '@atlassian/help-center-common-component/constants';
import { AdminManagementLayout } from '../admin-management-layout';
import { SettingsBreadcrumbs } from '../settings-breadcrumbs';
import messages from './messages';
import { FetchProjectsQuery, ProjectsList } from './projects-list';
import type { projectsList_GetProjectsQuery } from './projects-list/__generated__/projectsList_GetProjectsQuery.graphql';
import { SyncProjectsToggle } from './sync-projects-toggle';
import type { StatusTextMap } from './types';
import { ProjectsMappingStatus } from './types';

const PROJECT_MAPPING_SUPPORT_DOC_URL =
    'https://support.atlassian.com/jira-service-management-cloud/docs/create-and-manage-help-centers';

export const HelpCenterManageProjects: ComponentType = () => {
    di(useQueryLoader, isDefaultHelpCenter, getIsMultiHCEnabled);
    const { formatMessage } = useIntl();
    const [helpCenterMappingStatus, setHelpCenterMappingStatus] = React.useState<ProjectsMappingStatus>(
        ProjectsMappingStatus.LINKED
    );
    const [searchQuery, setSearchQuery] = React.useState<string>('');
    const [queryReference, loadQuery] = useQueryLoader<projectsList_GetProjectsQuery>(FetchProjectsQuery);
    const isReadOnlyView = !getIsMultiHCEnabled() || isDefaultHelpCenter();

    useEffect(() => {
        // TODO: Remove this once backend starts accepting HelpCenterAri
        const helpCenterId = getHelpCenterAri().substring(getHelpCenterAri().lastIndexOf('/') + 1);
        loadQuery({
            helpCenterId,
            helpCenterMappingStatus,
            cloudId: getCloudId(),
        });
    }, [helpCenterMappingStatus, loadQuery]);

    const onInputChange = (value: string): void => {
        setSearchQuery(value);
    };

    const debouncedOnChange = debounce(onInputChange, USER_TYPING_DEBOUNCE_TIME);

    const onTextFieldChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
        (event) => {
            const value = event.target.value;
            debouncedOnChange(value);
        },
        [debouncedOnChange]
    );

    const helpCenterManageProjectsLink = (chunks: ReactNode) => (
        <a href={PROJECT_MAPPING_SUPPORT_DOC_URL} target="_blank" rel="noreferrer">
            {chunks}
        </a>
    );

    const statusTextMap: StatusTextMap = {
        [ProjectsMappingStatus.LINKED]: formatMessage(messages.linkedFilterLabel),
        [ProjectsMappingStatus.UNLINKED]: formatMessage(messages.unlinkedFilterLabel),
    };

    return (
        <AdminManagementLayout featureName="Helpcenter manage projects">
            <Stack space="space.300">
                <SettingsBreadcrumbs helpCenterName={'Test helpcenter'} />
                <Heading size="large">{formatMessage(messages.manageProjectsPageTitle)}</Heading>
                {isReadOnlyView ? (
                    <Box>
                        <Text>
                            {formatMessage(
                                isVaRebrandToVsaEnabled()
                                    ? messages.manageProjectsPageDescriptionForDefaultHC
                                    : messages.manageProjectsPageDescriptionForDefaultHCOld,
                                {
                                    link: helpCenterManageProjectsLink,
                                }
                            )}
                        </Text>
                    </Box>
                ) : (
                    <Box>
                        <Text>
                            {formatMessage(messages.manageProjectsPageDescription, {
                                link: helpCenterManageProjectsLink,
                            })}
                        </Text>
                        <Box paddingBlockStart="space.200">
                            <Inline alignBlock="center">
                                <Text weight="medium" color="color.text.subtle">
                                    {formatMessage(messages.manageProjectPageToggleForDefaultLinking)}
                                </Text>
                                {/* TODO: initialize defaultSelected with value returned from BE*/}
                                <SyncProjectsToggle defaultSelected />
                            </Inline>
                        </Box>
                    </Box>
                )}
                <Box>
                    <Inline>
                        <Box xcss={searchInputStyles}>
                            <Textfield
                                name="search"
                                isCompact
                                elemAfterInput={
                                    <Box paddingInlineEnd="space.100">
                                        <Search size="small" label="search icon" />
                                    </Box>
                                }
                                defaultValue={searchQuery}
                                onChange={onTextFieldChange}
                                placeholder={formatMessage(messages.searchProjectsPlaceholder)}
                            />
                        </Box>
                        {!isReadOnlyView && (
                            <Box>
                                <DropdownMenu
                                    zIndex={500}
                                    trigger={statusTextMap[helpCenterMappingStatus]}
                                    shouldFitContainer
                                >
                                    <DropdownItemGroup>
                                        <DropdownItem
                                            onClick={() => setHelpCenterMappingStatus(ProjectsMappingStatus.LINKED)}
                                        >
                                            {statusTextMap[ProjectsMappingStatus.LINKED]}
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={() => setHelpCenterMappingStatus(ProjectsMappingStatus.UNLINKED)}
                                        >
                                            {statusTextMap[ProjectsMappingStatus.UNLINKED]}
                                        </DropdownItem>
                                    </DropdownItemGroup>
                                </DropdownMenu>
                            </Box>
                        )}
                    </Inline>
                </Box>
                <Suspense fallback={<Loading />}>
                    {queryReference && (
                        <ProjectsList
                            isReadOnlyView={isReadOnlyView}
                            queryReference={queryReference}
                            selection={helpCenterMappingStatus}
                            searchQuery={searchQuery}
                        />
                    )}
                </Suspense>
            </Stack>
        </AdminManagementLayout>
    );
};

const searchInputStyles = xcss({
    width: '288px',
    marginRight: 'space.200',
});
