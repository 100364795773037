import * as React from 'react';
import { isCSMHelpCenter } from 'util/advanced-help-center';
import { hasValidHelpCenterARI } from 'util/help-center-ari';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import type { PortalSummary as FeaturedAndSortedPortalsSummary } from 'rest/featured-and-sorted-portals';
import type { fetchFeaturedAndSortedPortalsModel } from 'state/actions/featured-and-sorted-portals';
import type { fetchRequestTypeSuggestionAction } from 'state/actions/request-type-suggestion';
import type { RequestTypeSuggestion } from 'state/persisted/request-type-suggestion';
import { ForgeFooterModule } from 'view/forge-ui/portal-footer';
import { ForgeHeaderModule } from 'view/forge-ui/portal-header';
import { HelpCenterContainer } from 'view/help-center/container';
import Topics from 'view/help-center-topics';
import * as mixins from 'view/styles/mixins';
import { sizes } from 'view/styles/sizes-viewport';
import { AnalyticsContext } from '@atlaskit/analytics-next';
import { token } from '@atlaskit/tokens';
import { ExperienceSuccess } from '@atlassian/help-center-common-component/analytics/experience-notifier';
import { AnnouncementBanner } from '@atlassian/help-center-common-component/announcement-banner';
import {
    HELP_CENTER,
    HEADER,
    SUB_HEADER,
    FOOTER,
    ConnectFragment,
} from '@atlassian/help-center-common-component/connect-fragment';
import { ErrorPage } from '@atlassian/help-center-common-component/error-page';
import { FORGE_HELP_CENTER } from '@atlassian/help-center-common-component/forge-ui/constants';
import { getEnv } from '@atlassian/help-center-common-util/env';
import UFOLoadHold from '@atlassian/react-ufo/load-hold';
import {
    FeaturedAndSortedPortalsListContainer,
    // eslint-disable-next-line rulesdir/no-styled-import-alias
    Root as PortalCardListRoot,
} from '../featured-and-sorted-portals-card-list/styled';
import { ProjectCardSkeleton } from '../project-card-skeleton';
import { RecentForms } from '../recent-forms';
import { CsmHelpCenter } from './csm-help-center';
import { FeaturedAndSortedPortals } from './featured-and-sorted-portals';
import { HelpCenterNoPortal } from './no-portal';
import { HelpCenterSinglePortal } from './single-portal';
import type { PersistedError } from 'state/persisted/types';

interface HelpCenterAnnouncement {
    announcementHeader: string | undefined;
    announcementMessage: string | undefined;
}

interface Props {
    fetchRequestTypeSuggestion: typeof fetchRequestTypeSuggestionAction;
    fetchFeaturedAndSortedPortals: typeof fetchFeaturedAndSortedPortalsModel;
    portals: FeaturedAndSortedPortalsSummary[];
    requestTypeSuggestion: RequestTypeSuggestion[];
    userLoggedIn: boolean;
    announcement?: HelpCenterAnnouncement;
    isDefaultViewLoading: boolean;
    error: PersistedError['error'] | undefined;
    total: number;
    defaultViewPortalsLength: number;
    isPortalHidingEnabled: boolean;
}

export default class HelpCenterContent extends React.Component<Props> {
    componentDidMount() {
        // make this call only if FF enabled and portals does not have any existing value
        if (!this.props.portals?.length) {
            this.props.fetchFeaturedAndSortedPortals({}, false);
        }

        this.props.fetchRequestTypeSuggestion({ type: this.props.userLoggedIn ? 'recent' : 'trending' });
    }

    getHelpCenterContent = (content: JSX.Element | undefined) => {
        return (
            <div data-testid="experience-success">
                <ExperienceSuccess location="help-center-content">
                    <Root>
                        <StyledConnectFragment page={HELP_CENTER} location={HEADER} />
                        <StyledConnectFragment page={HELP_CENTER} location={SUB_HEADER} />
                        {/*TODO - remove test-id for forge-header */}
                        <div data-testid="forge-header">{<ForgeHeaderModule page={FORGE_HELP_CENTER} />}</div>
                        {hasValidHelpCenterARI() ? <Topics /> : null}
                        {content}
                        <StyledConnectFragment page={HELP_CENTER} location={FOOTER} />
                        {/*TODO - remove test-id for forge-footer */}
                        <div data-testid="forge-footer">{<ForgeFooterModule page={FORGE_HELP_CENTER} />}</div>
                    </Root>
                </ExperienceSuccess>
            </div>
        );
    };

    loaderState = () => {
        return (
            <UFOLoadHold name="portal-list-loader">
                <Root>
                    {/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Disabled to rollout go/ui-styling-standard tooling, please resolve */}
                    <PortalCardListRoot className="sidebar-closed">
                        <FeaturedAndSortedPortalsListContainer>
                            {Array.from({ length: 6 }).map((_, index) => (
                                <ProjectCardSkeleton key={index} isBasic />
                            ))}
                        </FeaturedAndSortedPortalsListContainer>
                    </PortalCardListRoot>
                </Root>
            </UFOLoadHold>
        );
    };

    render() {
        const {
            portals,
            requestTypeSuggestion,
            announcement,
            isDefaultViewLoading,
            fetchFeaturedAndSortedPortals,
            error,
            total,
            defaultViewPortalsLength,
            isPortalHidingEnabled,
        } = this.props;
        let content;
        if (!isDefaultViewLoading) {
            if (isCSMHelpCenter(getEnv().helpCenterType)) {
                content = <CsmHelpCenter />;
            } else if (portals.length === 0) {
                // No portals
                content = isPortalHidingEnabled ? (
                    <Root>
                        <HelpCenterNoPortal />
                        {requestTypeSuggestion.length > 0 && (
                            <RecentForms suggestedRequestTypes={requestTypeSuggestion} />
                        )}
                    </Root>
                ) : (
                    <HelpCenterNoPortal />
                );
            } else if (portals.length === 1) {
                // Single portal - Help center is shown with data from the only portal the user can see.
                const portal = portals[0];
                content = <HelpCenterSinglePortal portalId={portal.id} requestTypeSuggestion={requestTypeSuggestion} />;
            } else {
                /* MultiPortal/FeaturedAndSortedPortals - help center is shown as a hub that links to other portals */
                content = (
                    <FeaturedAndSortedPortals
                        requestTypeSuggestions={requestTypeSuggestion}
                        fetchFeaturedAndSortedPortals={fetchFeaturedAndSortedPortals}
                    />
                );
            }
        }

        const analyticsData = {
            attributes: {
                numberOfDefaultViewPortals: defaultViewPortalsLength,
                numberOfExpandedViewPortals: total - defaultViewPortalsLength,
                numberOfRequestTypesSuggestion: requestTypeSuggestion.length,
            },
        };

        return (
            <AnalyticsContext data={analyticsData}>
                <>
                    {!!announcement && (
                        <AnnouncementBanner
                            actionSubjectId="helpCenterAnnouncementBanner"
                            header={announcement.announcementHeader}
                            messageHtml={announcement.announcementMessage}
                            InnerContainerComponent={HelpCenterContainer}
                        />
                    )}
                    <div data-test-id="help-center-content">
                        {isDefaultViewLoading && this.loaderState()}
                        {!isDefaultViewLoading && (
                            <ErrorPage error={error}>{this.getHelpCenterContent(content)}</ErrorPage>
                        )}
                    </div>
                </>
            </AnalyticsContext>
        );
    }
}

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const StyledConnectFragment = styled(ConnectFragment)`
    margin: auto;
    ${mixins.borderBox};
    margin-top: ${token('space.600', '48px')};
    max-width: ${sizes.small}px;
    padding-left: ${token('space.600', '48px')};
    padding-right: ${token('space.600', '48px')};
    width: 100%;
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const Root = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    margin: auto;
`;
