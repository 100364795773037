import { createRelayResource } from '@atlassian/help-center-common-util/resource-router';
import type { srcHelpCenterVirtualAgentEnabledQuery } from './__generated__/srcHelpCenterVirtualAgentEnabledQuery.graphql';
import HelpCenterVirtualAgentEnabledQuery from './__generated__/srcHelpCenterVirtualAgentEnabledQuery.graphql';

export const HelpCenterVirtualAgentResource = createRelayResource<srcHelpCenterVirtualAgentEnabledQuery>({
    type: 'HELP_REPORTING_PAGE',
    getQuery: (_, { cloudId, helpCenterAri }: { cloudId: string; helpCenterAri: string }) => {
        return {
            parameters: HelpCenterVirtualAgentEnabledQuery,
            variables: {
                cloudId,
                helpCenterAri,
            },
            options: {
                fetchPolicy: 'store-or-network',
            },
        };
    },
});
