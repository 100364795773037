import { isMultiHcKoshIntegrationEnabled } from 'feature-flags';
import * as qs from 'query-string';
import {
    isCSMHelpCenter,
    isAdvancedHelpCenter as isNewHelpCenter,
    isUnifiedHelpHelpCenter,
} from '@atlassian/help-center-common-util/advanced-help-center';
import { EnvType, getEnv } from '@atlassian/help-center-common-util/env';
import { getBaseName, getRelativeBasePath } from '@atlassian/help-center-common-util/history';
import { addParamsToUrl } from '@atlassian/help-center-common-util/history-qs';
import { origin, search } from '@atlassian/help-center-common-util/location';
import { QUERY_PARAM_SSO_TEST } from '@atlassian/help-center-common-util/login/constant';

interface LoginOptions {
    portalId?: number;
    isAbsolute?: boolean;
    redirectUrl?: string;
}

interface LoginSignUpRedirectUrlParams {
    destination?: string;
    isAbsolute: boolean;
}

interface RequestListParameters {
    page: number;
    reporter?: string;
    status?: string;
    statuses?: string[];
}

// Advanced help center has root URL as '/'
// Basic help center has root URL as '/portals'
export const HELP_CENTER_URL =
    isNewHelpCenter() || isUnifiedHelpHelpCenter() || isCSMHelpCenter(getEnv().helpCenterType) ? '/' : '/portals';
const ATLASSIAN_ACCOUNT_URL = 'https://id.atlassian.com';
const ATLASSIAN_ACCOUNT_STG_URL = 'https://id.stg.internal.atlassian.com';

const envToAtlassianAccountUrlMap = new Map([
    [EnvType.LOCAL, ATLASSIAN_ACCOUNT_STG_URL],
    [EnvType.DEV, ATLASSIAN_ACCOUNT_STG_URL],
    [EnvType.STAGING, ATLASSIAN_ACCOUNT_STG_URL],
    [EnvType.PROD, ATLASSIAN_ACCOUNT_URL],
]);

export const getLoginUrl = (loginOptions: LoginOptions): string => {
    return internalGetLoginUrl(loginOptions.isAbsolute, loginOptions.portalId, loginOptions.redirectUrl);
};

export const getLoginUrlForPortalId = (portalId?: number): string => {
    return portalId ? `/portal/${portalId}/user/login${search()}` : `/user/login${search()}`;
};

// TODO: remove this when https://jdog.jira-dev.com/browse/FSD-3964 has been completed and we don't need to use awkward string types anymore
export const getLoginUrlForPortalIdAsString = (portalIdAsString?: string): string => {
    return getLoginUrlForPortalId(stringToNumberOrUndefined(portalIdAsString));
};

export const getSignupUrl = (portalId?: number): string => {
    return portalId ? `/portal/${portalId}/user/signup${search()}` : `/user/signup${search()}`;
};

export const getRedirectUrlOrLoginUrlIfNotLoggedIn = (
    redirectToLogin: boolean,
    url: string,
    portalId?: number | undefined
) => {
    const strippedUrl = stripBasename(url);
    if (redirectToLogin) {
        return internalGetLoginUrl(false, portalId, strippedUrl);
    }
    return strippedUrl;
};

const internalGetLoginUrl = (
    isAbsolute: boolean | undefined,
    portalId: number | undefined,
    redirectUrl: string | undefined
) => {
    const pathUrl = portalId ? `portal/${portalId}/user/login` : 'user/login';
    const portalUrl = isAbsolute ? `${getBaseName()}/${pathUrl}` : `/${pathUrl}`;

    if (redirectUrl) {
        // trim leading slash if it is present
        const url = redirectUrl.replace(/^\/+/g, '');
        return addParamsToUrl(portalUrl, { destination: url });
    }

    return portalUrl;
};

export const getLoginUrlForTestMode = (loginOptions: LoginOptions) => {
    return addParamsToUrl(
        internalGetLoginUrl(loginOptions.isAbsolute, loginOptions.portalId, loginOptions.redirectUrl),
        { [QUERY_PARAM_SSO_TEST]: 'true' }
    );
};

export const parseLoginSignupRedirectUrlParams = (): LoginSignUpRedirectUrlParams => {
    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const parsedSearchQuery = qs.parse(search());

    return {
        // Suppressing existing violation. Please fix this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        destination: parsedSearchQuery.destination,
        // TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        isAbsolute: parsedSearchQuery.absolute === 'true',
    };
};

export const getManageAtlassianAccountUrl = () => {
    const url = '/plugins/servlet/redirectToProfile';
    return addParamsToUrl(url, { target: 'profile-visibility' });
};

export const getEditAccountPreferenceUrl = () => {
    const url = '/plugins/servlet/redirectToProfile';
    return addParamsToUrl(url, { target: 'edit-preference' });
};

export const getHelpCenterUrl = () => {
    return HELP_CENTER_URL;
};

export const getLearnMoreUrl = () => {
    return envToAtlassianAccountUrlMap.get(getEnv().environment)?.concat('/manage-profile/apps');
};

export const getPortalUrl = (portalId?: number) => {
    if (portalId) {
        return `/portal/${portalId}`;
    }
    return getHelpCenterUrl();
};

// TODO: remove this when https://jdog.jira-dev.com/browse/FSD-3964 has been completed and we don't need to use awkward string types anymore
export const getPortalUrlWithPortalIdAsString = (portalId?: string) => {
    if (portalId) {
        return `/portal/${portalId}`;
    }
    return getHelpCenterUrl();
};

export const stripBasename = (url: string): string => {
    if (url) {
        const pos = url.indexOf(getRelativeBasePath());
        if (pos >= 0) {
            return url.substring(pos + getRelativeBasePath().length);
        }
    }
    return url;
};

export const getRequestListUrl = (parameters: RequestListParameters) => {
    const queryParameters: string[] = [`page=${parameters.page}`];
    if (parameters.reporter) {
        queryParameters.push(`reporter=${parameters.reporter}`);
    }
    if (parameters.statuses) {
        // TypeScript upgrade (v4.4.3). Please correct when you revisit this code. Please correct when this code is revisited.
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        queryParameters.push(`statuses=${parameters.statuses}`);
    }

    return `/user/requests?${queryParameters.join('&')}`;
};

export const prependWithSlashIfAbsent = (toPrepend: string) => {
    if (toPrepend.startsWith('/')) {
        return toPrepend;
    }
    return `/${toPrepend}`;
};

const stringToNumberOrUndefined = (toConvert?: string) => (toConvert === undefined ? undefined : Number(toConvert));

export const parseIssueKeyFromUrl = (url: string): string | null => {
    const jiraUrlMatch = `${origin()}/browse/([A-Z][A-Z0-9_]+-\\d+)`;
    const portalUrlMatch = `${origin()}${getRelativeBasePath()}/portal/\\d+/([A-Z][A-Z0-9_]+-\\d+)`;

    const jiraMatch = url.match(jiraUrlMatch);
    if (jiraMatch) {
        return jiraMatch[1];
    }
    const portalMatch = url.match(portalUrlMatch);
    return portalMatch === null ? null : portalMatch[1];
};
// eslint-disable-next-line rulesdir/do-not-manually-build-jsm-agent-view-url
const getNextgenSettingsUrl = (projectKey: string): string => `/jira/servicedesk/projects/${projectKey}/settings`;
export const getNextgenSettingsLanguageUrl = (projectKey: string): string =>
    `${getNextgenSettingsUrl(projectKey)}/language`;
export const getNextgenSettingsRequestTypesUrl = (projectKey: string): string =>
    `${getNextgenSettingsUrl(projectKey)}/issuetypes`;
export const getNextgenSettingsRequestTypeUrl = (projectKey: string, requestTypeId: string): string =>
    `${getNextgenSettingsRequestTypesUrl(projectKey)}/${requestTypeId}`;

export const isForThisJiraTenant = (url: string, jiraBaseUrl: string): boolean => {
    return url.startsWith(jiraBaseUrl);
};

export const getMyPendingApprovalsUrl = () => {
    return `/user/approvals?approvalQueryType=myPending&page=1`;
};

export const isTestModeRequested = (ssoTest: string | undefined) => {
    return ssoTest === 'true';
};

export const getSsoTestModeParamFromUrl = (params: URLSearchParams) => {
    return params.get(QUERY_PARAM_SSO_TEST) === 'true';
};

const isAbsoluteUrl = (url: string): boolean => {
    try {
        new URL(url);
        return true;
    } catch {
        return false;
    }
};

export const convertAbsoluteUrlToRelativeUrl = (url: string): string => {
    if (isAbsoluteUrl(url)) {
        return url.replace(`${new URL(url).origin}`, '') || '/';
    }
    return url;
};

// This function is to be cleaned up once we get correct url from backend
// Ticket url -> https://hello.jira.atlassian.cloud/browse/PORTAL-4031
export const getUpdatedDisplayLink = (displayLink: string): string => {
    const isBasicHCRegex = /\/servicedesk\/customer\/.+/;
    if (
        isNewHelpCenter() &&
        isMultiHcKoshIntegrationEnabled() &&
        isBasicHCRegex.test(displayLink) &&
        !isAbsoluteUrl(displayLink)
    ) {
        return displayLink.replace('/servicedesk/customer/', '/');
    }

    return displayLink;
};

export const getUpdatedDisplayLinkForArticleSearch = (displayLink: string): string => {
    if (
        isNewHelpCenter() &&
        isMultiHcKoshIntegrationEnabled() &&
        displayLink.includes('/servicedesk/customer/') &&
        isAbsoluteUrl(displayLink)
    ) {
        return displayLink.replace(/.+\/servicedesk\/customer\//, '/');
    }

    return displayLink;
};

const ADMIN_STAGING_URL = 'http://admin.stg.atlassian.com';
const ADMIN_PROD_URL = 'https://admin.atlassian.com';

export const isProd = (): boolean => (window.location.host.includes('-dev.com') ? false : true);

export const CLOUD_ADMIN_URL = isProd() ? ADMIN_PROD_URL : ADMIN_STAGING_URL;

export const getBillingUrl = (products: string[], cloudId: string) => {
    const baseUrl = `${CLOUD_ADMIN_URL}/s/${cloudId}`;
    return products.length === 1
        ? `${baseUrl}/billing/applications/change-edition/${products[0]}.ondemand`
        : `${baseUrl}/billing-preview`;
};
