import { defineMessages } from 'react-intl-next';

export default defineMessages({
    projectsListLinkedLozengeLabel: {
        defaultMessage: 'Linked',
        description: 'Projects list linked lozenge label',
        id: 'projects.list.linked.lozenge.label',
    },
    projectsListUnlinkedLozengeLabel: {
        defaultMessage: 'Unlinked',
        description: 'Projects list unlinked lozenge label',
        id: 'projects.list.unlinked.lozenge.label',
    },
    projectsListLinkButtonText: {
        defaultMessage: 'Link',
        description: 'Projects list link button text',
        id: 'projects.list.link.button.text',
    },
    projectsListUnlinkButtonText: {
        defaultMessage: 'Unlink',
        description: 'Projects list unlink button text',
        id: 'projects.list.unlink.button.text',
    },
    projectsListEmptyStateDescription: {
        defaultMessage: 'When you unlink projects, they’ll appear here.',
        description: 'Projects list empty state description',
        id: 'projects.list.empty.description.non-final',
    },
    projectsListEmptyStateTitle: {
        defaultMessage: 'All your projects are linked to this help center',
        description: 'Projects list empty state title',
        id: 'projects.list.empty.title.non-final',
    },
    projectsListEmptySearchTitle: {
        defaultMessage: 'No projects found matching your search',
        description: 'Projects list empty search title',
        id: 'projects.list.empty.search.title.non-final',
    },
    projectsListEmptySearchDescription: {
        defaultMessage: 'Try using specific project names or terms',
        description: 'Projects list empty search description',
        id: 'projects.list.empty.search.description.non-final',
    },
    selectAllProjectsCheckboxLabel: {
        id: 'projects.list.select-all.projects.checkbox.label',
        defaultMessage: 'Select all projects',
        description: 'Checkbox label for selecting all projects',
    },
});
