import React, { useCallback } from 'react';
import type { FetchError } from 'util/fetch';
import { isFetchNetworkOrClientError } from 'util/fetch';
import { connect } from 'react-redux';
import { styled } from '@compiled/react';
import { showErrorFlag, showSuccessFlag } from '@helpCenter/state/actions/flags';
import { isProjectMappingInHelpCenterEnabled } from 'feature-flags';
import { useIntl } from 'react-intl-next';
import { di } from 'react-magnetic-di';
import { AnalyticsContext, useAnalyticsEvents, withAnalyticsContext } from '@atlaskit/analytics-next';
import Form, { RequiredAsterisk } from '@atlaskit/form';
import Heading from '@atlaskit/heading';
import CloseIcon from '@atlaskit/icon/glyph/cross';
import ModalDialog, { ModalBody, ModalFooter, ModalHeader, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog';
import { Box, Stack, Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { TRACK_EVENT_TYPE } from '@atlassian/analytics-web-react';
import { Button } from '@atlassian/help-center-common-component/analytics/button';
import { HELP_CENTERS_MANAGEMENT_BASE_PATH } from '@atlassian/help-center-common-component/constants';
import { NavigationPrompt } from '@atlassian/help-center-common-component/prompt';
import { getErrorData } from '@atlassian/help-center-common-util/analytics/listener';
import { getEnv } from '@atlassian/help-center-common-util/env';
import { getWorkspaceAri } from '@atlassian/help-center-common-util/meta';
import {
    ALREADY_EXISTED_HELP_CENTERS_MESSAGE,
    HARD_LIMIT_HELP_CENTERS_MESSAGE,
    RESERVED_TERM_HELP_CENTERS_MESSAGE,
} from '../../constants';
import { useHelpCenter } from '../../services/use-help-center';
import { addHelpCenterExperience, updateHelpCenterExperience } from './experiences';
import { HelpCenterFormFields } from './help-center-form-fields';
import { HelpCenterFormFooter } from './help-center-form-footer';
import messages from './messages';

interface InjectedProps {
    showErrorFlag: typeof showErrorFlag;
    showSuccessFlag: typeof showSuccessFlag;
}
export interface HelpCenterFormProps {
    onClose: () => void;
    isOpen: boolean;
    isStandaloneForm?: boolean;
    helpCenterToEdit?: {
        name: string;
        slug: string;
        translations: { locale: string; value: string }[];
        ari: string;
    };
}
const MODAL_WIDTH = '506px';

export interface FormData {
    name: string;
    slug: string;
}

const HelpCenterForm: React.FC<HelpCenterFormProps & InjectedProps> = ({
    onClose,
    isOpen,
    helpCenterToEdit,
    isStandaloneForm = false,
    showErrorFlag: showErrorFlagAction,
    showSuccessFlag: showSuccessFlagAction,
}) => {
    di(getEnv, useHelpCenter);
    const { formatMessage } = useIntl();
    const { createAnalyticsEvent } = useAnalyticsEvents();
    const { createHelpCenter, updateHelpCenter } = useHelpCenter();
    const displayUrl = getEnv().baseUrl.replace(/^https?:\/\//i, '');
    const { slug, name } = helpCenterToEdit || {};
    const successFlagAction = useCallback(
        (helpCenterSlug: string) => [
            {
                contentNode: (
                    <a href={`${getEnv().baseUrl}/helpcenter/${helpCenterSlug}`}>
                        {formatMessage(messages.helpCenterAddSuccessDesc)}
                    </a>
                ),
            },
        ],
        [formatMessage]
    );

    const onCreate = useCallback(
        ({ helpCenterName, helpCenterSlug }: { helpCenterName: string; helpCenterSlug: string }) => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            addHelpCenterExperience.start();
            return createHelpCenter({
                helpCenterName,
                helpCenterSlug,
            })
                .then((response) => {
                    createAnalyticsEvent({
                        analyticsType: TRACK_EVENT_TYPE,
                        action: 'created',
                        actionSubject: 'helpCenter',
                        helpCenterAri: response.helpCenter?.createHelpCenter?.helpCenterAri,
                    }).fire();
                    showSuccessFlagAction(
                        messages.helpCenterAddSuccessTitle,
                        {
                            value: helpCenterName,
                        },
                        undefined,
                        successFlagAction(helpCenterSlug)
                    );
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    addHelpCenterExperience.success();
                    onClose();
                    if (isProjectMappingInHelpCenterEnabled()) {
                        setTimeout(() => {
                            window.location.href = `/helpcenter/${helpCenterSlug}/manage-projects`;
                        }, 1000);
                    }
                })
                .catch((error: FetchError) => {
                    const { message, status } = error;
                    if (message === HARD_LIMIT_HELP_CENTERS_MESSAGE) {
                        showErrorFlagAction(
                            messages.helpCenterHardLimitErrorTitle,
                            messages.helpCenterHardLimitErrorDescription,
                            undefined,
                            undefined,
                            { value: message }
                        );
                    } else if (message === RESERVED_TERM_HELP_CENTERS_MESSAGE) {
                        showErrorFlagAction(
                            messages.helpCenterAddErrorTitle,
                            messages.helpCenterReservedSlugErrorDescription
                        );
                    } else if (message === ALREADY_EXISTED_HELP_CENTERS_MESSAGE) {
                        showErrorFlagAction(
                            messages.helpCenterAddErrorTitle,
                            messages.helpCenterAlreadyExistedSlugErrorDescription
                        );
                    } else {
                        showErrorFlagAction(messages.helpCenterAddErrorTitle, messages.helpCenterGenericErrorDesc);
                    }

                    if (!isFetchNetworkOrClientError(error)) {
                        createAnalyticsEvent({
                            analyticsType: TRACK_EVENT_TYPE,
                            action: 'create_failed',
                            actionSubject: 'helpCenter',
                            errorMessage: message,
                            errorStatus: status,
                        }).fire();
                        return addHelpCenterExperience.failure({
                            metadata: {
                                error: getErrorData(message),
                            },
                        });
                    }

                    return addHelpCenterExperience.success();
                });
        },
        [createAnalyticsEvent, createHelpCenter, onClose, showErrorFlagAction, showSuccessFlagAction, successFlagAction]
    );

    const onEdit = useCallback(
        ({
            helpCenterName,
            helpCenterTranslations,
            helpCenterSlug,
            helpCenterAri,
        }: {
            helpCenterName?: string;
            helpCenterTranslations?: {
                locale: string;
                value: string;
            }[];
            helpCenterSlug?: string;
            helpCenterAri: string;
        }) => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            updateHelpCenterExperience.start();
            return updateHelpCenter({
                helpCenterAri,
                helpCenterName,
                helpCenterTranslations,
                helpCenterSlug,
            })
                .then(() => {
                    createAnalyticsEvent({
                        helpCenterAri,
                        analyticsType: TRACK_EVENT_TYPE,
                        action: 'updated',
                        actionSubject: 'helpCenter',
                    }).fire();
                    showSuccessFlagAction(messages.helpCenterUpdateSuccessTitle, {
                        value: helpCenterName ? helpCenterName : name,
                    });
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    updateHelpCenterExperience.success();
                    if (isStandaloneForm) {
                        window.location.href = HELP_CENTERS_MANAGEMENT_BASE_PATH;
                    } else {
                        onClose();
                    }
                })
                .catch((error: FetchError) => {
                    const { message, status } = error;
                    if (message === RESERVED_TERM_HELP_CENTERS_MESSAGE) {
                        showErrorFlagAction(
                            messages.helpCenterUpdateErrorTitle,
                            messages.helpCenterReservedSlugErrorDescription
                        );
                    } else if (message === ALREADY_EXISTED_HELP_CENTERS_MESSAGE) {
                        showErrorFlagAction(
                            messages.helpCenterUpdateErrorTitle,
                            messages.helpCenterAlreadyExistedSlugErrorDescription
                        );
                    } else {
                        showErrorFlagAction(messages.helpCenterUpdateErrorTitle, messages.helpCenterGenericErrorDesc);
                    }

                    if (!isFetchNetworkOrClientError(error)) {
                        createAnalyticsEvent({
                            helpCenterAri,
                            analyticsType: TRACK_EVENT_TYPE,
                            action: 'update_failed',
                            actionSubject: 'helpCenter',
                            errorMessage: message,
                            errorStatus: status,
                        }).fire();
                        return updateHelpCenterExperience.failure({
                            metadata: {
                                error: getErrorData(message),
                            },
                        });
                    }

                    return updateHelpCenterExperience.success();
                });
        },
        [
            createAnalyticsEvent,
            name,
            isStandaloneForm,
            onClose,
            showErrorFlagAction,
            showSuccessFlagAction,
            updateHelpCenter,
        ]
    );

    const onSubmit = useCallback(
        (data: FormData) => {
            const { name: helpCenterName, slug: helpCenterSlug } = data;
            if (!helpCenterToEdit) {
                return onCreate({ helpCenterName, helpCenterSlug });
            }
            const { ari, translations } = helpCenterToEdit;
            return onEdit({
                helpCenterName: helpCenterName !== name ? helpCenterName : undefined,
                helpCenterSlug: helpCenterSlug !== slug ? helpCenterSlug : undefined,
                helpCenterAri: ari,
                helpCenterTranslations: translations,
            });
        },
        [helpCenterToEdit, name, onCreate, onEdit, slug]
    );

    const helpCenterDetailsPageDescription = messages.helpCenterDetailsPageDescription;

    // used in settings page without a modal
    if (isStandaloneForm) {
        return (
            <Form<FormData> onSubmit={onSubmit}>
                {({ formProps, dirty, submitting, reset }) => (
                    <form {...formProps}>
                        <Stack space="space.300">
                            <Box paddingBlockStart="space.200">
                                <Heading size="large">{formatMessage(messages.helpCenterDetailsPageTitle)}</Heading>
                            </Box>
                            <Box>
                                <Text>
                                    {slug
                                        ? formatMessage(helpCenterDetailsPageDescription)
                                        : formatMessage(messages.defaultHelpCenterDetailsPageDescription)}
                                </Text>
                            </Box>
                            <Box paddingBlock="space.100">
                                <Text>
                                    {formatMessage(messages.helpCenterRequiredFieldsWarning)}
                                    <RequiredAsterisk />
                                </Text>
                            </Box>
                        </Stack>
                        <HelpCenterFormFields
                            mode="EDIT"
                            name={name}
                            slug={slug}
                            urlWithoutSlug={`${window.location.hostname}/helpcenter`}
                        />
                        <HelpCenterFormFooter
                            mode="EDIT"
                            isStandaloneForm
                            isCancelDisabled={!dirty}
                            submitting={submitting}
                            onCancel={() =>
                                reset({
                                    name: name ?? '',
                                    slug: slug ?? '',
                                })
                            }
                        />
                    </form>
                )}
            </Form>
        );
    }

    // TODO clean this up when enable_project_mapping_for_help_center is cleaned up.
    return (
        <ModalTransition>
            {isOpen && (
                <AnalyticsContext data={{ attributes: { workspaceAri: getWorkspaceAri() } }}>
                    <ModalDialog onClose={onClose} width={MODAL_WIDTH} testId="help-centers-management-form-modal">
                        <Form<FormData> onSubmit={onSubmit}>
                            {({ formProps, submitting }) => (
                                <HelpForm {...formProps}>
                                    <ModalHeader>
                                        <ModalTitle testId="help-centers-management-modal-title">
                                            {formatMessage(
                                                !helpCenterToEdit
                                                    ? messages.helpCenterCreateFormTitle
                                                    : messages.helpCenterEditFormTitle
                                            )}
                                        </ModalTitle>
                                        <Button
                                            appearance="subtle"
                                            iconBefore={<CloseIcon label="cancel create dialog" />}
                                            onClick={onClose}
                                            actionSubjectId="helpCenterCreateDialogCancelButton"
                                        />
                                    </ModalHeader>
                                    <ModalBody>
                                        {!helpCenterToEdit && (
                                            <DescriptionWrapper>
                                                {formatMessage(messages.helpCenterCreateFormDescription)}
                                            </DescriptionWrapper>
                                        )}
                                        <HelpCenterFormFields
                                            mode={!helpCenterToEdit ? 'CREATE' : 'EDIT'}
                                            name={name}
                                            slug={slug}
                                            urlWithoutSlug={`${displayUrl}/helpcenter`}
                                        />
                                    </ModalBody>
                                    <ModalFooter>
                                        <HelpCenterFormFooter
                                            submitting={submitting}
                                            onCancel={onClose}
                                            mode={helpCenterToEdit ? 'EDIT' : 'CREATE'}
                                        />
                                    </ModalFooter>
                                </HelpForm>
                            )}
                        </Form>
                    </ModalDialog>
                    <NavigationPrompt preventNavigation={isOpen} />
                </AnalyticsContext>
            )}
        </ModalTransition>
    );
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const DescriptionWrapper = styled.div({
    marginBottom: token('space.250', '20px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const HelpForm = styled.form({
    height: '100%',
});

export default connect(null, {
    showErrorFlag,
    showSuccessFlag,
})(withAnalyticsContext({ component: 'helpCenterForm' })(HelpCenterForm));
