import { defineMessages } from 'react-intl-next';

export default defineMessages({
    projectsListLinkButtonText: {
        defaultMessage: 'Link',
        description: 'Projects list link button text',
        id: 'projects.link.unlink.button.link.button.text',
    },
    projectsListUnlinkButtonText: {
        defaultMessage: 'Unlink',
        description: 'Projects list unlink button text',
        id: 'projects.link.unlink.button.unlink.button.text',
    },
    unlinkModalTitle: {
        defaultMessage: 'Unlink this project?',
        description: 'Unlink project modal title',
        id: 'projects.link.unlink.button.unlink.modal.title.non-final',
    },
    unlinkModalDesc: {
        defaultMessage: `All help resources from the project {projectName} will be removed from the help center and will not show up in search.`,
        description: 'Unlink project modal description',
        id: 'projects.link.unlink.button.unlink.modal.description.non-final',
    },
    unlinkModalConfirmButton: {
        defaultMessage: 'Unlink',
        description: 'Unlink project modal confirm button',
        id: 'projects.link.unlink.button.unlink.modal.confirm.button.non-final',
    },
    unlinkModalCancelButton: {
        defaultMessage: 'Cancel',
        description: 'Unlink project modal cancel button',
        id: 'projects.link.unlink.button.unlink.modal.cancel.button',
    },
    unlinkSuccessTitle: {
        defaultMessage: `{value} is no longer linked to this help center`,
        description: 'Unlink project flag success title',
        id: 'projects.link.unlink.button.unlink.flag.success.title.non-final',
    },
    linkSuccessTitle: {
        defaultMessage: `{projectName} is now linked to this help center`,
        description: 'Link project flag success title',
        id: 'projects.link.unlink.button.link.flag.success.title.non-final',
    },
    unlinkErrorTitle: {
        defaultMessage: 'Unlinking failed',
        description: 'Unlink project flag error title',
        id: 'projects.link.unlink.button.unlink.flag.error.title.non-final',
    },
    linkErrorTitle: {
        defaultMessage: `We couldn’t link the project {projectName}`,
        description: 'Link project flag error title',
        id: 'projects.link.unlink.button.link.flag.error.title',
    },
    unlinkErrorDescription: {
        defaultMessage: 'Refresh the page and try again.',
        description: 'Unlink project flag error description',
        id: 'projects.link.unlink.button.unlink.flag.error.description.',
    },
    lastProjectUnlink: {
        defaultMessage:
            'Your help center needs at least one linked project to ensure that your customers can access relevant information and resources.',
        description: 'Last project unlink project dialog message',
        id: 'projects.link.unlink.button.unlink.last.project.dialog.content.non-final',
    },
});
