import type { ResourceStoreContext } from 'react-resource-router/resources';
import { createResourcesPlugin } from 'react-resource-router/resources';
import { PROJECT_NAME } from 'routes/common/constants';
import { getCloudId, getHelpCenterAri, getWorkspaceAri, getLayoutAri } from '@atlassian/help-center-common-util/meta';

const customContext: ResourceStoreContext = {
    cloudId: getCloudId(),
    productName: PROJECT_NAME,
    helpCenterAri: getHelpCenterAri(),
    workspaceAri: getWorkspaceAri(),
    layoutAri: getLayoutAri(),
};

export const resourcesPlugin = createResourcesPlugin({
    context: customContext,
});
