import { createRelayResource } from '@atlassian/help-center-common-util/resource-router';
import type { ConversationPageVirtualAgentQuery } from './__generated__/ConversationPageVirtualAgentQuery.graphql';
import conversationPageVirtualAgentQuery from './__generated__/ConversationPageVirtualAgentQuery.graphql';

export const HelpCenterVirtualAgentResource = createRelayResource<ConversationPageVirtualAgentQuery>({
    type: 'HELP_CENTER_CONVERSATION_PAGE',
    getQuery: (_, { cloudId, helpCenterAri }: { cloudId: string; helpCenterAri: string }) => {
        return {
            parameters: conversationPageVirtualAgentQuery,
            variables: {
                cloudId,
                helpCenterAri,
            },
            options: {
                fetchPolicy: 'store-or-network',
            },
        };
    },
});
