/**
 *  Adapted from JFE:
 *  https://stash.atlassian.com/projects/JIRACLOUD/repos/jira-frontend/browse/src/packages/common-legacy-do-not-add-anything-new/src/util/get-feature-flag-value.js
 *
 */

import { get } from 'lodash';
import FeatureGates from '@atlaskit/feature-gate-js-client';
import { setBooleanFeatureFlagResolver } from '@atlaskit/platform-feature-flags';
import getAllFeatureFlags from './get-all-feature-flags';
import type { FlagValue, Flags } from './types';
import { getFFValueFromClient } from './use-feature-flag';

type IsFeatureEnabled = ({ key, fallback }: { key: string; fallback: boolean }) => boolean;

let isFeatureEnabledOld: undefined | IsFeatureEnabled;
let isFeatureEnabledNew: undefined | IsFeatureEnabled;

export const isFeatureEnabled: IsFeatureEnabled = ({ key, fallback }) => {
    if (isFeatureEnabledOld) {
        return isFeatureEnabledOld({
            key,
            fallback,
        });
    }
    if (isFeatureEnabledNew) {
        return isFeatureEnabledNew({
            key,
            fallback,
        });
    }
    // when jsd-dw-portal-wrm-optimisations FF is being rolled out we no longer require 'jira/featureflags/feature-manager' via the monolith
    // in that case we fallback to the new getBooleanFeatureFlag function adapted from JFE
    // we store and reuse the reference to the current FF function, so subsequent calls don't have to try and require the wrm version of feature-manager multiple times
    try {
        // Suppressing existing violation. Please fix this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const { isFeatureEnabled: jiraMonolithFeatureManager } = window.require('jira/featureflags/feature-manager');

        if (jiraMonolithFeatureManager) {
            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            isFeatureEnabledOld = jiraMonolithFeatureManager;

            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
            return jiraMonolithFeatureManager({
                key,
                fallback,
            });
        }

        isFeatureEnabledNew = getBooleanFeatureFlag;
        return getBooleanFeatureFlag({
            key,
            fallback,
        });
    } catch (e) {
        isFeatureEnabledNew = getBooleanFeatureFlag;
        return getBooleanFeatureFlag({
            key,
            fallback,
        });
    }
};

const getFlagValue = (featureFlags: Flags, key: string, fallback: boolean | string) => {
    const value = get(featureFlags, [key]);

    //get flag value from Fx3 client if not found in meta tags
    if (value === undefined) {
        return { value: getFFValueFromClient(key, fallback) };
    }

    return value;
};

export const getBooleanFeatureFlag = ({ key, fallback }: { key: string; fallback: boolean }): boolean => {
    const featureFlags = getAllFeatureFlags();
    const flagValue = getFlagValue(featureFlags, key, fallback);

    if (typeof flagValue === 'undefined') {
        return fallback;
    }

    let result: FlagValue = fallback;
    if (typeof flagValue === 'object' && 'value' in flagValue) {
        result = flagValue.value;
    }

    if (typeof result === 'boolean') {
        return result;
    }
    return fallback;
};

const getFeatureFlagValue = <T extends FlagValue>(featureFlagName: string, defaultValue: T): FlagValue => {
    const featureFlags = getAllFeatureFlags();
    const flagValue = getFlagValue(featureFlags, featureFlagName, defaultValue as string | boolean);

    if (typeof flagValue === 'undefined') {
        return defaultValue;
    }

    if (typeof flagValue === 'object' && 'value' in flagValue) {
        return flagValue.value;
    }
    return flagValue;
};

export const initialisePlatformFeatureFlags = () => {
    setBooleanFeatureFlagResolver(
        (flagKey) =>
            isFeatureEnabled({
                key: flagKey,
                fallback: false,
            }) || FeatureGates.checkGate(flagKey)
    );
};

export default getFeatureFlagValue;
