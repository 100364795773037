/**
 * DO NOT EDIT.
 *
 * This is Relay generated artifact.
 *
 * Please reach out to #jsm-cloud-megatron for any questions.
 *
 * @generated SignedSource<<23795ce57f63178446aa6444e6bfed31>>
 * @relayHash 182f16dc43b639efca413d787f04bd05
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 2ad5af6a2f1bd246e8e70ee503eba0224b581456cbf407a55b96aaba827efc67

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type searchResultRequestFormQuery$variables = {
  cloudId: string;
  portalIds?: ReadonlyArray<string> | null | undefined;
  queryTerm: string;
  resultLimit?: number | null | undefined;
};
export type searchResultRequestFormQuery$data = {
  readonly helpObjectStore: {
    readonly __typename: "HelpObjectStoreQueryApi";
    readonly " $fragmentSpreads": FragmentRefs<"requestFormsFragment">;
  };
};
export type searchResultRequestFormQuery = {
  response: searchResultRequestFormQuery$data;
  variables: searchResultRequestFormQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "portalIds"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "queryTerm"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "resultLimit"
},
v4 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v5 = [
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "searchResultRequestFormQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v5/*: any*/),
          "concreteType": "HelpObjectStoreQueryApi",
          "kind": "LinkedField",
          "name": "helpObjectStore",
          "plural": false,
          "selections": [
            (v6/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "requestFormsFragment"
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "helpObjectStore"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "searchResultRequestFormQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "HelpObjectStoreQueryApi",
        "kind": "LinkedField",
        "name": "helpObjectStore",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "alias": "requestForms",
            "args": [
              {
                "fields": [
                  (v4/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "entityType",
                    "value": "REQUEST_FORM"
                  },
                  {
                    "kind": "Variable",
                    "name": "portalIds",
                    "variableName": "portalIds"
                  },
                  {
                    "kind": "Variable",
                    "name": "queryTerm",
                    "variableName": "queryTerm"
                  },
                  {
                    "kind": "Variable",
                    "name": "resultLimit",
                    "variableName": "resultLimit"
                  }
                ],
                "kind": "ObjectValue",
                "name": "searchInput"
              }
            ],
            "concreteType": null,
            "kind": "LinkedField",
            "name": "searchHelpObjects",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isExternal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "searchBackend",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayLink",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "containerDisplayName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "searchAlgorithm",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "entityType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "iconUrl",
                    "storageKey": null
                  }
                ],
                "type": "HelpObjectStoreSearchResult",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "message",
                    "storageKey": null
                  }
                ],
                "type": "HelpObjectStoreQueryError",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v7/*: any*/)
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "2ad5af6a2f1bd246e8e70ee503eba0224b581456cbf407a55b96aaba827efc67",
    "metadata": {},
    "name": "searchResultRequestFormQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "ab9c6a6fbeb1bc1acbb0c27aa2edaa24";

export default node;
