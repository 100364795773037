import { loadQuery } from 'react-relay';

import type { PreloadedQuery, GraphQLTaggedNode, VariablesOf, LoadQueryOptions } from 'react-relay';
import type { RouteResource, RouterContext, RouterDataContext, ResourceStoreContext } from 'react-resource-router';
import { createResource, useResource } from 'react-resource-router';
import { getRequest } from 'relay-runtime';
import type { OperationType } from 'relay-runtime';

import { getRelayEnvironment } from '@atlassian/help-center-common-util/relay';

interface Config<TQuery extends OperationType> {
    parameters: GraphQLTaggedNode;
    variables?: VariablesOf<TQuery>;
    options?: LoadQueryOptions;
}

export const createRelayResource = <TQuery extends OperationType>({
    type,
    getQuery,
}: {
    type: string;
    getQuery: (routerContext: RouterContext | RouterDataContext, customContext: ResourceStoreContext) => Config<TQuery>;
}) =>
    createResource<PreloadedQuery<TQuery>>({
        type: `RELAY_RESOURCE_TYPE_${type}`,
        getKey: (routerContext: RouterContext, customContext: ResourceStoreContext) => {
            const { variables, parameters } = getQuery(routerContext, customContext);
            //@ts-ignore this key does not really matter since max-age is set to zero. This util is a polyfill and will be done away with once we adopt Relay entry points.
            const request = getRequest(parameters);
            const params = request.params;
            const queryId = 'cacheID' in params && params.cacheID != null ? params.cacheID : params.id;
            return `${String(queryId)}${JSON.stringify(variables || {})}`;
        },
        getData: (routerContext: RouterDataContext, customContext: ResourceStoreContext) => {
            const { parameters, variables, options } = getQuery(routerContext, customContext);
            const queryReference = loadQuery<TQuery>(getRelayEnvironment(), parameters, variables || {}, options);
            return Promise.resolve(queryReference);
        },
        maxAge: 0,
    });

export const useRelayResource = <Query extends OperationType>(resource: RouteResource<PreloadedQuery<Query>>) => {
    const { data: queryReference } = useResource(resource);
    return {
        queryReference,
    };
};
