import { createStore, createHook, createActionsHook } from 'react-sweet-state';

import { ASSISTANT_MESSAGE_STATUS } from '../../rest/converse';
import {
    fetchChannelId,
    startNewConversation,
    onPipelineChange,
    setTesting,
    setConversationScrollBarWidth,
    recordFeedbackForConversation,
    refetchConversation,
    addHelpseekerMessage,
    addHelpseekerMessageOnlyForUI,
    addFeedbackResponseOnlyForUI,
    addRetryMessageOnlyForUI,
    setFeedbackCollectorConfig,
    clearConversation,
    abortConversation,
    takeOverConversationFromVA,
    addCsatResponseOnlyForUI,
} from './actions';
import { ASSISTANT_USER_TYPE, HELP_SEEKER_USER_TYPE, initialState } from './constants';
import type { AssistantMessage, ChatMessage, State } from './types';

const store = createStore({
    initialState,
    actions: {
        fetchChannelId,
        startNewConversation,
        onPipelineChange,
        setTesting,
        setConversationScrollBarWidth,
        recordFeedbackForConversation,
        refetchConversation,
        addHelpseekerMessage,
        addHelpseekerMessageOnlyForUI,
        addFeedbackResponseOnlyForUI,
        addRetryMessageOnlyForUI,
        setFeedbackCollectorConfig,
        clearConversation,
        abortConversation,
        takeOverConversationFromVA,
        addCsatResponseOnlyForUI,
    },
    name: 'CONVERSE',
});

export const useConversation = createHook(store, {
    selector: (state) => state.conversation,
});

export const useConversationId = createHook(store, {
    selector: (state) => state.conversation?.conversationId,
});

export const useConversationActions = createActionsHook(store);

export const useIsTesting = createHook(store, {
    selector: (state) => state.isTesting,
});
export const useFeedbackCollectorConfig = createHook(store, {
    selector: (state) => state.feedbackCollectorConfig,
});

export const useConversationScrollBarWidth = createHook(store, {
    selector: (state) => state.ui.conversationScrollBarWidth,
});

const findBotOrAssistantMessage =
    (messageId: string | undefined) =>
    (chatMessage: ChatMessage): chatMessage is AssistantMessage => {
        return (
            chatMessage &&
            chatMessage.userType === ASSISTANT_USER_TYPE &&
            chatMessage.messageMetadata.messageId === messageId
        );
    };

export const useIsChannelErrorPresent = createHook(store, {
    selector: (state: State) => {
        if (!state.conversation) {
            return false;
        }
        const lastMessage = state.conversation.history.slice(-1)[0];
        return lastMessage?.userType === 'channelMessage';
    },
});

export const getMessageFeedback = createHook(store, {
    selector: (state, messageId: string | undefined) => {
        const message = state.conversation?.history.find(findBotOrAssistantMessage(messageId));
        return message ? message?.messageMetadata.messageFeedback : undefined;
    },
});

export const getCsatScore = createHook(store, {
    selector: (state, messageId: string | undefined) => {
        const message = state.conversation?.history.find(findBotOrAssistantMessage(messageId));
        return message ? message.messageMetadata.csatScore : undefined;
    },
});

const findHelpSeekerOrAssistantMessage = (chatMessage: ChatMessage) => {
    return (
        chatMessage && (chatMessage.userType === ASSISTANT_USER_TYPE || chatMessage.userType === HELP_SEEKER_USER_TYPE)
    );
};

export const getLastMessageId = createHook(store, {
    selector: (state) => {
        const message = state.conversation?.history.filter(findHelpSeekerOrAssistantMessage).at(-1);
        return message ? message.messageMetadata.messageId : undefined;
    },
});

export const useIsLastAssistantMessageGenerated = createHook(store, {
    selector: (state: State) => {
        const { conversation, isLoading } = state;
        const lastMessage = conversation?.history.slice(-1)[0];
        const storeAbortController = conversation?.storeAbortController;
        if (lastMessage && lastMessage.userType === ASSISTANT_USER_TYPE && storeAbortController) {
            const {
                messageMetadata: { status },
            } = lastMessage;

            if (!status) {
                return false;
            }

            return (
                [ASSISTANT_MESSAGE_STATUS.IDLE, ASSISTANT_MESSAGE_STATUS.ABORTED_RESPONSE].indexOf(status) !== -1 &&
                !isLoading
            );
        }
        return true;
    },
});

export const useActions = createActionsHook(store);

export const useIsLastMessageFromHelpSeeker = createHook(store, {
    selector: (state) =>
        // After first message, conversation is an object but has no history
        // To stop the user from sending another message here, we are waiting for history array to be filled
        state.conversation?.history.length === 0 ||
        state.conversation?.history.slice(-1)[0]?.userType === HELP_SEEKER_USER_TYPE,
});

export const useIsFeedbackSkipped = createHook(store, {
    selector: (state, messageId: string | undefined) => {
        const message = state.conversation?.history.find(findBotOrAssistantMessage(messageId));
        return message ? message?.messageMetadata.isFeedbackSkipped : undefined;
    },
});

export const useIsCsatSkipped = createHook(store, {
    selector: (state, messageId: string | undefined) => {
        const message = state.conversation?.history.find(findBotOrAssistantMessage(messageId));
        return message ? message.messageMetadata.isCsatSkipped : undefined;
    },
});
