import * as React from 'react';
import { useIntl } from 'react-intl-next';
import type { PortalSummary } from 'rest/featured-and-sorted-portals';
import type { SelectValues } from '@atlassian/paginated-picker';
import { SimplePicker } from '../picker';
import messages from './messages';
import type { ServicedeskFilterProps } from './types';

const ServiceDeskFilter: React.FunctionComponent<ServicedeskFilterProps> = ({
    portals,
    portalIds,
    fetchPortals,
    onChange,
}) => {
    const { formatMessage } = useIntl();
    const [values, _] = React.useState(portalIds ?? []);

    React.useEffect(() => {
        fetchPortals({ exploreAll: true, sortBy: 'POPULARITY' }, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const options: SelectValues = React.useMemo(() => {
        return portals.map((portal: PortalSummary) => ({
            optionType: 'option',
            value: portal.id.toString(),
            label: portal.name,
        }));
    }, [portals]);

    const preSelectedOptions: SelectValues = React.useMemo(() => {
        const preselectedPortals = portals.filter((portal: PortalSummary) =>
            values.find((id) => id === portal.id.toString())
        );
        return preselectedPortals.map((portal: PortalSummary) => ({
            optionType: 'option',
            value: portal.id.toString(),
            label: portal.name,
        }));
    }, [portals, values]);

    return portals.length > 1 ? (
        <SimplePicker
            fieldId="servicedesk_filter"
            fieldLabel={formatMessage(messages.serviceDeskFilter)}
            options={options}
            preSelectedOptions={preSelectedOptions}
            onChange={onChange}
            testId="serviceDeskFilter"
        />
    ) : null;
};

export default ServiceDeskFilter;
