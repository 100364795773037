import React, { useCallback, type FC } from 'react';
import { styled } from '@compiled/react';
import { isVaRebrandToVsaEnabled } from 'feature-flags';
import { useIntl } from 'react-intl-next';
import { di } from 'react-magnetic-di';
import { useRouter } from 'react-resource-router';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import Heading from '@atlaskit/heading';
import CommentIcon from '@atlaskit/icon/glyph/comment';
import { Box, xcss, Inline } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { TRACK_EVENT_TYPE } from '@atlassian/analytics-web-react';
import { FireScreenEventOnMount } from '@atlassian/help-center-common-util/analytics/fire-screen-event';
import messages from './messages';

export interface VirtualAgentBannerProps {
    cloudId?: string;
    portalId?: string | number;
}

export const VirtualAgentBanner: FC<VirtualAgentBannerProps> = ({ cloudId, portalId }) => {
    di(useAnalyticsEvents, useRouter, FireScreenEventOnMount);
    const scope = portalId ? 'portal' : 'helpCenter';
    const { formatMessage } = useIntl();
    const { createAnalyticsEvent } = useAnalyticsEvents();

    const [, { push }] = useRouter();

    const chatWithVirtualAgentForPortal = useCallback(() => {
        // https://data-portal.internal.atlassian.com/analytics/registry/66942
        createAnalyticsEvent({
            analyticsType: TRACK_EVENT_TYPE,
            action: 'clicked',
            actionSubject: 'chatWithVAButton',
        }).fire();

        if (portalId) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            push(`/portal/${portalId}/conversation`);
        }
    }, [push, portalId, createAnalyticsEvent]);

    const chatWithVirtualAgentForHelpCenter = useCallback(() => {
        createAnalyticsEvent({
            analyticsType: TRACK_EVENT_TYPE,
            action: 'clicked',
            actionSubject: 'chatWithVAButtonOnHelpCenter',
        }).fire();
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        push('/conversation');
    }, [push, createAnalyticsEvent]);

    return (
        <Box xcss={containerStyles} role="status" aria-live="polite">
            <Inline spread="space-between" space="space.100" xcss={contentStyles}>
                <Box>
                    <Heading as="h2" level="h400">
                        {formatMessage(messages.virtualAgentBannerHeading)}
                    </Heading>
                    <Message>
                        {isVaRebrandToVsaEnabled()
                            ? formatMessage(messages.virtualServiceAgentEAPBannerDescription)
                            : formatMessage(messages.virtualAgentEAPBannerDescription)}
                    </Message>
                </Box>
                <Button
                    iconBefore={<CommentIcon label="" />}
                    appearance="primary"
                    onClick={scope === 'portal' ? chatWithVirtualAgentForPortal : chatWithVirtualAgentForHelpCenter}
                >
                    {isVaRebrandToVsaEnabled()
                        ? formatMessage(messages.buttonTextV2)
                        : formatMessage(messages.buttonText)}
                </Button>
            </Inline>
            <FireScreenEventOnMount
                customData={{
                    name: 'virtualAgentBanner',
                    attributes: {
                        cloudId,
                        portalId,
                        state: 'visible',
                    },
                }}
            />
        </Box>
    );
};

const containerStyles = xcss({
    background: token('color.background.accent.blue.subtlest', '#E9F2FF'),
    padding: 'space.300',
    borderRadius: 'border.radius.100',
});

const contentStyles = xcss({
    alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const Message = styled.p({
    marginTop: token('space.100', '8px'),
});
