/**
 * DO NOT EDIT.
 *
 * This is Relay generated artifact.
 *
 * Please reach out to #jsm-cloud-megatron for any questions.
 *
 * @generated SignedSource<<f130a93a4103380449af23fb0a6e9610>>
 * @relayHash 556788beaf5bc55f841b84db7730bdef
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 2a1cdecb6044c1c22653e61cea2a33183107750a93fa5adea612731b1f5c0fc2

import type { ConcreteRequest, Query } from 'relay-runtime';
export type metricGridReportingQuery$variables = {
  cloudId: string;
  helpCenterAri: string;
};
export type metricGridReportingQuery$data = {
  readonly helpCenter: {
    readonly helpCenterReportingById: {
      readonly __typename: "HelpCenterReporting";
      readonly performanceIndicatorsWithMetaData: {
        readonly performanceIndicators: ReadonlyArray<{
          readonly currentValue: string;
          readonly name: string;
          readonly previousValue: string | null | undefined;
          readonly timeWindow: string | null | undefined;
        }> | null | undefined;
      } | null | undefined;
    } | {
      readonly __typename: "QueryError";
      readonly message: string | null | undefined;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null | undefined;
  } | null | undefined;
};
export type metricGridReportingQuery = {
  response: metricGridReportingQuery$data;
  variables: metricGridReportingQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "helpCenterAri"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "cloudId",
        "variableName": "cloudId"
      }
    ],
    "concreteType": "HelpCenterQueryApi",
    "kind": "LinkedField",
    "name": "helpCenter",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "helpCenterAri",
            "variableName": "helpCenterAri"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "helpCenterReportingById",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "HelpCenterReportingPerformanceIndicatorsWithMetaData",
                "kind": "LinkedField",
                "name": "performanceIndicatorsWithMetaData",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "HelpCenterReportingPerformanceIndicator",
                    "kind": "LinkedField",
                    "name": "performanceIndicators",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "currentValue",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "previousValue",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "timeWindow",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "HelpCenterReporting",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              }
            ],
            "type": "QueryError",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "metricGridReportingQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "metricGridReportingQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "2a1cdecb6044c1c22653e61cea2a33183107750a93fa5adea612731b1f5c0fc2",
    "metadata": {},
    "name": "metricGridReportingQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "cfc71e0ffb7c4a3b5fa9a415550fca40";

export default node;
