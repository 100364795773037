// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled, { keyframes } from 'styled-components';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

interface SkeletonItemProps {
    height: string;
    width: string;
}

const shimmer = keyframes({
    '0%': {
        backgroundPosition: '-300px 0',
    },
    '100%': {
        backgroundPosition: '1000px 0',
    },
});

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const SkeletonItem = styled.div<SkeletonItemProps>`
    animation-duration: 1.2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${shimmer};
    animation-timing-function: linear;
    background-color: ${token('color.skeleton', colors.N20)};
    background-image: linear-gradient(
        to right,
        ${token('color.skeleton', colors.N20)} 10%,
        ${token('color.skeleton.subtle', colors.N30)} 20%,
        ${token('color.skeleton', colors.N20)} 30%
    );
    background-repeat: no-repeat;
    border-radius: ${token('border.radius.100', '4px')};
    height: ${({ height }) => height};
    width: ${({ width }) => width};
`;
