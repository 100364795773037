import { connect } from 'react-redux';
import type { State } from '@helpCenter/state';
import { getSharedPortalName, getHelpCenterState } from '@helpCenter/state/selectors/help-center';
import Sidebar from './sidebar';

const selector = (state: State) => ({
    currentHcName: getSharedPortalName(state),
    currentHcBannerUrl: getHelpCenterState(state).helpCenterBannerUrl,
    currentHcThemeColor: getHelpCenterState(state).themeColor,
    useDefaultBanner: getHelpCenterState(state).useDefaultBanner,
});
export default connect(selector, {})(Sidebar);
