import { getEnv, EnvType, getIsPageServerSideRendered } from 'util/env';
import { getMeta } from 'util/meta';
import { getMark } from 'util/perf-markers';
import { getAnalyticsWebClientPromise } from '@atlassian/help-center-common-util/analytics/client';
import { init } from '@atlassian/react-ufo/interaction-metrics-init';
import traceUFOPageLoad from '@atlassian/react-ufo/trace-pageload';
import { retrieveAndSetPageLoadTrace } from '../retrieve-and-set-page-load-trace';

export const initUFO = () => {
    retrieveAndSetPageLoadTrace();

    const isStagingEnv = getEnv().environment === EnvType.STAGING;
    init(
        Promise.resolve({
            getAnalyticsWebClientPromise,
        }),
        {
            product: 'jira',
            enableCompleteUsingDoubleRAF: true,
            region: getMeta('ajs-region') || 'unknown', //TODO:SSR and FF data
            namePrefix: 'help-center-ufo-',
            ssr: {
                getSSRDoneTime: () => getMark('ssr.spa.rendered')?.startTime,
            },
            capability: {
                /*
                 * Enabling feature flag data to be captured in UFO.
                 */
                feature_flag_access: 1,
                /*
                 * https://atlassian.slack.com/archives/C01AY8132S3/p1723030532244489?thread_ts=1722580624.223629&cid=C01AY8132S3
                 * react_profiler is added since it is required by the type,
                 * will be cleaned up in the future
                 */
                react_profiler: 0,
            },
            vc: {
                /*
                 * https://stash.atlassian.com/projects/ATLASSIAN/repos/atlassian-frontend-monorepo/pull-requests/129126/diff#platform%2F.changeset%2Fred-lemons-mate.md
                 */
                stopVCAtInteractionFinish: true,
                enabled: true,
                selectorConfig: {
                    className: isStagingEnv,
                    role: isStagingEnv,
                    id: isStagingEnv,
                    testId: isStagingEnv,
                },
                ssr: getIsPageServerSideRendered(),
                devToolsEnabled: isStagingEnv,
            },
            kind: {
                /*
                 * Sampling works in 1 in the rate specified.
                 * If rate is 1, it captures all events - 1/1
                 */
                page_load: 1,
                transition: 1,
                press: 1,
                typing: 1,
                legacy: 0, //For some Jira events that will be cleaned up in UFO
                hover: 1,
            },
        }
    );

    /*
     * Start instrumenting the page load event.
     * We invoke `traceUFOPageLoad` once more
     * when we have the route context.
     * It attaches the route name to the event.
     * */
    traceUFOPageLoad();
};
