import { useMemo, useState } from 'react';
import { useIsoMorphicLayoutEffect } from 'util/hooks';
import type { GetValueOptions, SupportedFlagTypes } from '@atlassiansox/feature-flag-web-client';
import { reportError } from '@atlassian/help-center-common-util/sentry';

import { getFeatureFlagClient } from '../feature-flag-client';

export const getFFValueFromClient = <T extends SupportedFlagTypes>(
    flagKey: string,
    defaultValue: T,
    options?: GetValueOptions<T>
) => {
    let value = defaultValue;
    const featureFlagClient = getFeatureFlagClient();

    if (featureFlagClient) {
        try {
            value = featureFlagClient.getFlagValue(flagKey, defaultValue, options);
        } catch (err) {
            reportError(err);
        }
    }

    return value;
};

export const useFeatureFlag = <T extends SupportedFlagTypes>(
    flagKey: string,
    defaultValue: T,
    options?: GetValueOptions<T>
) => {
    const initialValue = useMemo(() => {
        return getFFValueFromClient(flagKey, defaultValue, options);
    }, [flagKey, defaultValue, options]);

    const [flagValue, setFlagValue] = useState(initialValue);

    useIsoMorphicLayoutEffect(() => {
        const initialFlagValue = getFFValueFromClient(flagKey, defaultValue, options);
        setFlagValue(initialFlagValue);

        const unsubscribe = getFeatureFlagClient().on(
            flagKey,
            defaultValue,
            (newFlagValue) => {
                setFlagValue(newFlagValue);
            },
            options
        );

        return unsubscribe;
    }, [flagKey, defaultValue, options]);

    return flagValue;
};
