import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { isVaRebrandToVsaEnabled } from 'feature-flags';
import { useIntl } from 'react-intl-next';
import { di } from 'react-magnetic-di';
import Lozenge from '@atlaskit/lozenge';
import { CustomItem } from '@atlaskit/menu';
import { SpotlightTarget, SpotlightTransition } from '@atlaskit/onboarding';
import type { Link } from '@atlassian/help-center-common-component/analytics';
import { EngagementMessages, EngagementMessagesTarget } from '@atlassian/help-center-common-component/constants';
import { EngagementCoordinationClient } from '@atlassian/help-center-common-component/engagement-coordination-client';
import { RenderNextAnimationFrame } from '@atlassian/help-center-common-component/render-next-animation-frame';
import { VaOnboardingSpotlight } from '@atlassian/help-center-common-component/va-reporting-onboarding';
import messages from './messages';

interface VirtualAgentMenuProps {
    onClose: (actionSubjectId?: string) => void;
    component: typeof Link;
}

export const VirtualAgentMenu = ({ onClose, component }: VirtualAgentMenuProps) => {
    di(VaOnboardingSpotlight, RenderNextAnimationFrame);
    const { formatMessage } = useIntl();
    const [showVaReportingOnboardingSpotlight, setShowVaReportingOnboardingSpotlight] = useState(true);

    const handleClick = () => onClose('navBarVirtualAgent');

    return (
        <>
            <RenderNextAnimationFrame>
                {() => (
                    <SpotlightTarget
                        name={EngagementMessagesTarget.REPORTING_ONBOARDING_SPOTLIGHT}
                        key="navBarVirtualAgent"
                    >
                        <CustomItem
                            key={`customize-menu_navBarVirtualAgent`}
                            component={component}
                            to="/virtual-agent"
                            actionSubjectId="navBarVirtualAgent"
                            onClick={handleClick}
                            testId="virtual-agent-link"
                            iconAfter={<Lozenge appearance="new">{formatMessage(messages.beta)}</Lozenge>}
                        >
                            {isVaRebrandToVsaEnabled() ? (
                                <FormattedMessage {...messages.virtualServiceAgent} />
                            ) : (
                                <FormattedMessage {...messages.virtualAgent} />
                            )}
                        </CustomItem>
                    </SpotlightTarget>
                )}
            </RenderNextAnimationFrame>
            {showVaReportingOnboardingSpotlight && (
                <SpotlightTransition>
                    <EngagementCoordinationClient
                        messageId={EngagementMessages.ONBOARD_VA_REPORTING}
                        key={EngagementMessagesTarget.REPORTING_ONBOARDING_SPOTLIGHT}
                    >
                        <VaOnboardingSpotlight
                            setShowVaReportingOnboardingSpotlight={setShowVaReportingOnboardingSpotlight}
                        />
                    </EngagementCoordinationClient>
                </SpotlightTransition>
            )}
        </>
    );
};
