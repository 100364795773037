import { connect } from 'react-redux';
import { fetchHelpCenterBrandingAction } from '@helpCenter/epics/model/help-center';
import { fetchUserModelAction } from '@helpCenter/epics/model/user';
import { fetchUserRequestsCountsAction } from '@helpCenter/epics/model/user-requests-counts';
import Header from './header';

export default connect(null, {
    fetchBranding: fetchHelpCenterBrandingAction,
    fetchUser: fetchUserModelAction,
    fetchUserRequestsCounts: fetchUserRequestsCountsAction,
})(Header);
