import React, { useCallback } from 'react';
import { getCloudId, getHelpCenterAri } from 'util/meta';
import type { showErrorFlag, showSuccessFlag } from '@helpCenter/state/actions/flags';
import { useIntl } from 'react-intl-next';
import { di } from 'react-magnetic-di';
import { graphql, useMutation } from 'react-relay';
import Button, { LoadingButton } from '@atlaskit/button';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog';
import type { TriggerProps } from '@atlaskit/popup';
import Popup from '@atlaskit/popup';
import { Box, xcss } from '@atlaskit/primitives';
import { layers } from '@atlaskit/theme';
import { ProjectsMappingOperations, ProjectsMappingStatus } from '../types';
import type {
    HelpCenterProjectMappingOperationType,
    linkUnlinkProjectMutation,
    linkUnlinkProjectMutation$data,
} from './__generated__/linkUnlinkProjectMutation.graphql';
import messages from './messages';

export interface Props {
    projectId: string;
    projectName: string;
    selection: string;
    isLastProject: boolean;
    isDisabled: boolean;
    showErrorFlag: typeof showErrorFlag;
    showSuccessFlag: typeof showSuccessFlag;
}

const LinkUnlinkProject = ({
    projectId,
    projectName,
    selection,
    isLastProject,
    isDisabled,
    showErrorFlag: showErrorFlagAction,
    showSuccessFlag: showSuccessFlagAction,
}: Props) => {
    di(useMutation, getCloudId, getHelpCenterAri);
    const { formatMessage } = useIntl();
    const [openUnlinkDialog, setOpenUnlinkDialog] = React.useState(false);
    const [isUnlinking, setIsUnlinking] = React.useState(false);
    const [isLinking, setIsLinking] = React.useState(false);
    const [isLastUnlink, setIsLastUnlink] = React.useState(false);
    const [linkUnlinkProject] = useMutation<linkUnlinkProjectMutation>(graphql`
        mutation linkUnlinkProjectMutation($cloudId: ID!, $input: HelpCenterProjectMappingUpdateInput!) {
            helpCenter(cloudId: $cloudId) {
                updateProjectMapping(input: $input) {
                    success
                    errors {
                        message
                    }
                }
            }
        }
    `);

    const onLinkUnlinkProjects = useCallback(
        (projectIds: string[], operationType: HelpCenterProjectMappingOperationType) => {
            return new Promise<linkUnlinkProjectMutation$data>((resolve, reject) =>
                linkUnlinkProject({
                    variables: {
                        cloudId: getCloudId(),
                        input: {
                            projectIds,
                            operationType,
                            helpCenterAri: getHelpCenterAri(),
                        },
                    },
                    updater: (store, response) => {
                        // TODO: Remove this once backend starts accepting HelpCenterAri
                        const helpCenterId = getHelpCenterAri().substring(getHelpCenterAri().lastIndexOf('/') + 1);
                        if (response?.helpCenter?.updateProjectMapping?.success) {
                            const root = store.getRoot();
                            const jiraRoot = store.getRoot().getLinkedRecord('jira');
                            const connection = root
                                ?.getLinkedRecord('jira')
                                ?.getLinkedRecord('jiraProjectsMappedToHelpCenter', {
                                    cloudId: getCloudId(),
                                    filter: { helpCenterId, helpCenterMappingStatus: selection },
                                });
                            if (connection) {
                                const connectionEdges = connection?.getLinkedRecords('edges');
                                if (connectionEdges) {
                                    const newEdges = connectionEdges.filter(
                                        (edge) => edge?.getLinkedRecord('node')?.getValue('projectId') !== projectId
                                    );
                                    connection?.setLinkedRecords(newEdges, 'edges');
                                }
                            }
                            if (jiraRoot) {
                                // this is required so that switching between linked and unlinked projects makes a fresh query
                                jiraRoot.invalidateRecord();
                            }
                        }
                    },
                    onCompleted: (response) => {
                        if (response.helpCenter?.updateProjectMapping?.success) {
                            resolve(response);
                        }
                        const errors = response.helpCenter?.updateProjectMapping?.errors;
                        const error = errors && errors.length > 0 ? errors[0] : new Error('Unknown error');
                        return reject(error);
                    },
                    onError: (error) => {
                        reject(error);
                    },
                })
            );
        },
        [linkUnlinkProject, projectId, selection]
    );

    const onLinkProject = useCallback(() => {
        setIsLinking(true);
        onLinkUnlinkProjects([projectId], ProjectsMappingOperations.LINK)
            .then(() => {
                showSuccessFlagAction(messages.linkSuccessTitle, { projectName });
            })
            .catch(() => {
                showErrorFlagAction(messages.linkErrorTitle, messages.unlinkErrorDescription, [], { projectName });
            })
            .finally(() => {
                setIsLinking(false);
            });
    }, [onLinkUnlinkProjects, projectId, showSuccessFlagAction, showErrorFlagAction, projectName]);

    const onUnLinkProject = useCallback(() => {
        if (isLastProject) {
            setIsLastUnlink(true);
        } else {
            setOpenUnlinkDialog(true);
        }
    }, [isLastProject]);

    const onUnlinkDialogButtonClick = useCallback(() => {
        setIsUnlinking(true);
        onLinkUnlinkProjects([projectId], ProjectsMappingOperations.UNLINK)
            .then(() => {
                setIsUnlinking(false);
                setOpenUnlinkDialog(false);
                showSuccessFlagAction(messages.unlinkSuccessTitle, {
                    value: projectName,
                });
            })
            .catch(() => {
                setIsUnlinking(false);
                setOpenUnlinkDialog(false);
                showErrorFlagAction(messages.unlinkErrorTitle, messages.unlinkErrorDescription);
            });
    }, [onLinkUnlinkProjects, projectId, projectName, showErrorFlagAction, showSuccessFlagAction]);

    const contentCallback = useCallback(() => {
        return <Box xcss={unlinkDialogPopupStyles}>{formatMessage(messages.lastProjectUnlink)}</Box>;
    }, [formatMessage]);

    const triggerCallback = useCallback(
        (triggerProps: TriggerProps) => {
            return (
                <LoadingButton
                    {...triggerProps}
                    appearance="link"
                    onClick={onUnLinkProject}
                    isLoading={isLinking}
                    isDisabled={isDisabled}
                >
                    {formatMessage(messages.projectsListUnlinkButtonText)}
                </LoadingButton>
            );
        },
        [formatMessage, isLinking, onUnLinkProject, isDisabled]
    );

    return (
        <>
            {selection === ProjectsMappingStatus.LINKED ? (
                <Popup
                    isOpen={isLastUnlink}
                    onClose={() => setIsLastUnlink(false)}
                    placement="bottom-end"
                    zIndex={layers.modal()}
                    content={contentCallback}
                    trigger={triggerCallback}
                />
            ) : (
                <LoadingButton appearance="link" onClick={onLinkProject} isLoading={isLinking} isDisabled={isDisabled}>
                    {formatMessage(messages.projectsListLinkButtonText)}
                </LoadingButton>
            )}
            <ModalTransition>
                {openUnlinkDialog && (
                    <Modal width="small" onClose={() => setOpenUnlinkDialog(false)}>
                        <ModalHeader>
                            <ModalTitle appearance="warning" testId="help-center-unlink-modal-title">
                                {formatMessage(messages.unlinkModalTitle)}
                            </ModalTitle>
                        </ModalHeader>
                        <ModalBody>{formatMessage(messages.unlinkModalDesc, { projectName })}</ModalBody>
                        <ModalFooter>
                            <LoadingButton
                                onClick={onUnlinkDialogButtonClick}
                                isLoading={isUnlinking}
                                appearance="warning"
                                testId="help-center-card-delete-modal-delete-button"
                            >
                                {formatMessage(messages.unlinkModalConfirmButton)}
                            </LoadingButton>
                            <Button onClick={() => setOpenUnlinkDialog(false)} appearance="subtle">
                                {formatMessage(messages.unlinkModalCancelButton)}
                            </Button>
                        </ModalFooter>
                    </Modal>
                )}
            </ModalTransition>
        </>
    );
};

const unlinkDialogPopupStyles = xcss({
    maxWidth: '230px',
    padding: 'space.200',
});

export default LinkUnlinkProject;
