import React, { useCallback, useEffect, useRef, useState } from 'react';
import { isSearchUIRedesignEnabled } from 'feature-flags';
import { useIntl } from 'react-intl-next';
import { useRouterActions } from 'react-resource-router';
import type { UpdateSearchAction, ShowSearchAction } from 'state/actions/search';
import { withAnalyticsEvents } from '@atlaskit/analytics-next';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import CrossIcon from '@atlaskit/icon/glyph/editor/close';
import SearchIcon from '@atlaskit/icon/glyph/search';
import { Box, Grid, Inline, xcss } from '@atlaskit/primitives';
import { media } from '@atlaskit/primitives/responsive';
import Textfield from '@atlaskit/textfield';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { BackButton } from '@atlassian/help-center-common-component/back-button';
import { addParamsToUrl } from '@atlassian/help-center-common-util/history-qs';
import { SEARCH_BAR_HEIGHT, useQueryParams } from '../common';
import messages from './messages';

export interface Props {
    term: string;
    onChange: ((term: string) => UpdateSearchAction) | ((term: string) => void);
    onClose: () => ShowSearchAction;
    createAnalyticsEvent?: CreateUIAnalyticsEvent;
}

export const SearchBar = ({ term, onChange, createAnalyticsEvent }: Props) => {
    const { formatMessage } = useIntl();
    const [backButtonText, setBackButtonText] = useState<string>();
    const searchBarRef = useRef(null);

    useEffect(() => {
        let observer: ResizeObserver;
        const currentSearchBarRefRef = searchBarRef.current;

        if (window.ResizeObserver) {
            observer = new window.ResizeObserver((entries) => {
                for (const entry of entries) {
                    setBackButtonText(entry.contentRect.width < 768 ? '' : undefined);
                }
            });

            if (currentSearchBarRefRef) {
                observer.observe(currentSearchBarRefRef);
            }
        }

        return () => {
            if (currentSearchBarRefRef) observer.unobserve(currentSearchBarRefRef);
        };
    }, []);

    const onSearchTermChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onChange(e.target.value);

            if (createAnalyticsEvent) {
                createAnalyticsEvent({
                    analyticsType: 'UI',
                    action: 'changed',
                    actionSubject: 'searchTermValue',
                    actionSubjectId: 'searchTerm',
                }).fire();
            }
        },
        [createAnalyticsEvent, onChange]
    );

    useEffect(() => {
        if (createAnalyticsEvent) {
            createAnalyticsEvent({
                analyticsType: 'UI',
                action: 'rendered',
                actionSubject: 'searchBarTextField',
                actionSubjectId: 'searchBar',
            }).fire();
        }
    }, [createAnalyticsEvent]);

    const { goBack, push } = useRouterActions();

    const onBackButtonClick = useCallback(() => {
        goBack() as unknown as void;
    }, [goBack]);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const queryStringParams = useQueryParams();
    const onCrossIconClick = useCallback(() => {
        push(addParamsToUrl('', { ...queryStringParams, q: '' })) as unknown as void;
    }, [push, queryStringParams]);

    if (!isSearchUIRedesignEnabled()) {
        return (
            <Box
                // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
                xcss={xcss({
                    display: 'flex',
                    position: 'fixed',
                    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
                    height: SEARCH_BAR_HEIGHT,
                    width: '100%',
                    backgroundColor: 'elevation.surface.overlay',
                    borderBottomWidth: '1px',
                    borderBottomStyle: 'solid',
                    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
                    borderBottomColor: token('color.border', colors.N30),
                    zIndex: 'modal',
                })}
                ref={searchBarRef}
            >
                <Inline alignBlock="center" alignInline="center" grow="fill">
                    <Box
                        // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
                        xcss={xcss({
                            position: 'fixed',
                            left: token('space.050', '4px'),
                            [media.above.sm]: {
                                left: token('space.200', '16px'),
                            },
                            [media.above.xs]: {
                                left: token('space.100', '8px'),
                            },
                        })}
                    >
                        <BackButton text={backButtonText} actionSubjectId="closeSearch" onClick={onBackButtonClick} />
                    </Box>
                    <Box
                        // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
                        xcss={xcss({
                            display: 'flex',
                            flexGrow: '1',
                            height: token('space.400', '32px'),
                            maxWidth: '85%',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            [media.above.sm]: {
                                maxWidth: '50%',
                            },
                        })}
                    >
                        <Textfield
                            value={term}
                            name={formatMessage(messages.inputLabel)}
                            aria-label={formatMessage(messages.inputPlaceholder)}
                            onChange={onSearchTermChange}
                            placeholder={formatMessage(messages.inputPlaceholder)}
                            elemBeforeInput={
                                <Box
                                    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
                                    xcss={xcss({
                                        marginLeft: token('space.100', '8px'),
                                    })}
                                >
                                    <SearchIcon label="" size="small" primaryColor={token('color.icon', colors.N500)} />
                                </Box>
                            }
                            // Disabling existing violations, should be fixed when revisited.
                            // eslint-disable-next-line jsx-a11y/no-autofocus
                            autoFocus
                        />
                    </Box>
                </Inline>
            </Box>
        );
    }

    return (
        <Inline xcss={containerStyles} ref={searchBarRef}>
            <Grid gap="space.200" templateColumns="25fr 50fr 25fr" alignItems="center">
                <Box xcss={backButtonStyles}>
                    <BackButton text={backButtonText} actionSubjectId="closeSearch" onClick={onBackButtonClick} />
                </Box>
                <Box xcss={textFieldStyles}>
                    <Textfield
                        value={term}
                        name={formatMessage(messages.inputLabel)}
                        aria-label={formatMessage(messages.inputPlaceholder)}
                        onChange={onSearchTermChange}
                        placeholder={formatMessage(messages.inputPlaceholder)}
                        elemBeforeInput={
                            <Box xcss={searchIconStyles}>
                                <SearchIcon label="" size="medium" primaryColor={token('color.icon', colors.N500)} />
                            </Box>
                        }
                        elemAfterInput={
                            <Box as="button" xcss={crossIconStyles} onClick={onCrossIconClick}>
                                <CrossIcon label="" size="medium" primaryColor={token('color.icon', colors.N500)} />
                            </Box>
                        }
                        // Disabling existing violations, should be fixed when revisited.
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        autoFocus
                    />
                </Box>
                <Box />
            </Grid>
        </Inline>
    );
};

const containerStyles = xcss({
    display: 'flex',
    position: 'fixed',
    alignItems: 'center',
    width: '100vw',
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    height: SEARCH_BAR_HEIGHT,
    backgroundColor: 'elevation.surface.overlay',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    borderBottomColor: token('color.border', colors.N30),
    zIndex: 'modal',
});
const backButtonStyles = xcss({
    // maxWidth: '80px',
    [media.above.xxs]: {
        marginLeft: 'space.050',
    },
    [media.above.xs]: {
        marginLeft: 'space.100',
    },
    [media.above.sm]: {
        marginLeft: 'space.200',
    },
});
const textFieldStyles = xcss({
    height: 'space.500',
    marginLeft: 'space.100',
    marginRight: 'space.100',
    width: '965px',
    [media.above.xxs]: {
        minWidth: '348px',
    },
    [media.above.xs]: {
        minWidth: '432px',
    },
    [media.above.sm]: {
        minWidth: '692px',
    },
    [media.above.md]: {
        minWidth: '965px',
    },
    background: 'transparent',
});
const searchIconStyles = xcss({
    padding: 'space.100',
});
const crossIconStyles = xcss({
    background: 'transparent',
    cursor: 'pointer',
});

export default withAnalyticsEvents()(SearchBar);
