export { default as FlagGroup } from './hocs';

import messages from './messages';
export const GenericErrorTitle = messages.genericErrorTitle;
export const GenericErrorDescription = messages.genericErrorDescription;
export const GenericSignUpErrorDescriptionUnified = messages.genericSignUpErrorDescriptionUnified;
export const GenericSignUpErrorNoContactLinkDescription = messages.genericSignUpErrorNoContactLinkDescription;
export const RefreshPageLinkText = messages.refreshPageLinkText;
export const TellUsAboutIssueLinkText = messages.tellUsAboutIssueLinkText;
export const LetUsKnowLinkText = messages.letUsKnowLinkText;
export const GenericErrorRefreshPageAction = messages.genericErrorRefreshPageAction;
export const NoNetworkTitle = messages.noNetworkTitle;
export const SignupEmailResendRateLimitedDescription = messages.signupEmailResendRateLimitedDescription;
export const NoNetworkDescription = messages.noNetworkDescription;
export const SignupDomainBlockedDescription = messages.blockedDescription;
export const SignupDomainBlockedTitle = messages.blockedTitle;
export const SignupForbiddenTitle = messages.forbiddenTitle;
export const SignupForbiddenDescription = messages.forbiddenDescription;
export const InvalidEmailTitle = messages.invalidEmailTitle;
export const InvalidEmailDescription = messages.invalidEmailDescription;
export const UserProfileForgotPasswordFailedErrorTitle = messages.userProfileForgotPasswordFailedFlagTitle;
export const UserProfileForgotPasswordFailedErrorDescription = messages.userProfileForgotPasswordFailedFlagDescription;
