/**
 * This implementation is copied from Jira frontend.
 */
import { QueryResponseCache as RelayQueryResponseCache, stableCopy } from 'relay-runtime';
import type { GraphQLResponse, Variables } from 'relay-runtime';

const CACHE_NAME = 'RELAY_SSR_CACHE';
interface RequestMetadata {
    queryName: string;
    operationKind: string;
}

export const getCacheKey = (queryID: string, variables?: Variables): string =>
    JSON.stringify(stableCopy({ queryID, variables }));

// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
export const getQueryIDFromCacheKey = (cacheKey: string): string => JSON.parse(cacheKey).queryID;

/**
 * Query response cache that works across SSR. Extends on Relay's QueryResponse Cache:
 * https://github.com/facebook/relay/blob/main/packages/relay-runtime/network/RelayQueryResponseCache.js
 *
 * Can properly handle multiple requests of the same ID (Relay's Cache ID is based on query, not variables),
 * and includes a TTL and maximum cache size to further prevent stale data.
 */
export class SSRQueryResponseCache extends RelayQueryResponseCache {
    cacheName: string;
    requestMetadata: Map<string, RequestMetadata>;
    constructor({ cacheName, size, ttl }: { cacheName: string; size: number; ttl: number }) {
        super({ size, ttl });
        const cachedData = __SERVER__ ? {} : window?.SPA_STATE?.[cacheName] || {};
        this.cacheName = cacheName;
        this.requestMetadata = new Map(undefined);
        // @ts-expect-error - TS2339 - Property '_responses' does not exist on type 'SSRQueryResponseCache'.
        this._responses = new Map(cachedData.responses);
        setTimeout(() => {
            // cleanup leftover data to reduce unnecessary memory usage
            this.requestMetadata.clear();
            // @ts-expect-error - TS2339 - Property '_responses' does not exist on type 'SSRQueryResponseCache'.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
            this._responses.clear();
        }, 60 * 1000 /* 1 minute */);
    }
    setWithMetadata(queryID: string, variables: Variables, payload: GraphQLResponse, metadata: RequestMetadata): void {
        this.requestMetadata.set(queryID, metadata);
        this.set(queryID, variables, payload);
    }
    /**
     * Deletes a request from the store, if found.
     *
     * IMPORTANT: Make sure DELETE method always has tests that include get/set
     * to ensure our `getCacheKey` matches Relay's.
     *
     * @returns {boolean} indicating if an entry in the cache was deleted
     */
    delete(queryID: string, variables: Variables): boolean {
        const cacheKey = getCacheKey(queryID, variables);
        // @ts-expect-error - TS2339 - Property '_responses' does not exist on type 'SSRQueryResponseCache'.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
        return this._responses.delete(cacheKey);
    }
    serialize(): Partial<Record<typeof CACHE_NAME, [string, GraphQLResponse][]>> {
        return {
            [this.cacheName]: {
                requestMetadata: Array.from(this.requestMetadata.entries()),
                // @ts-expect-error - TS2339 - Property '_responses' does not exist on type 'SSRQueryResponseCache'.
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                responses: Array.from(this._responses.entries()),
            },
        };
    }
}

// Export a singleton, already configured for use with the SSR Cache.
export const QueryResponseCache = new SSRQueryResponseCache({
    cacheName: CACHE_NAME,
    size: Infinity,
    ttl: Infinity,
});
export const serializeQueryResponseCache = (): Partial<Record<typeof CACHE_NAME, [string, GraphQLResponse][]>> =>
    QueryResponseCache.serialize();
