import React from 'react';
import { useIntl } from 'react-intl-next';
import { Box } from '@atlaskit/primitives';
import { BreadCrumb } from '@atlassian/help-center-common-component/bread-crumbs/bread-crumb-dumb';
import { HELP_CENTERS_MANAGEMENT_BASE_PATH } from '@atlassian/help-center-common-component/constants';
import messages from './messages';

interface SettingsBreadcrumbsProps {
    helpCenterName: string;
}
const SettingsBreadcrumbs = ({ helpCenterName }: SettingsBreadcrumbsProps) => {
    const { formatMessage } = useIntl();
    return (
        <Box paddingBlockStart="space.400">
            <BreadCrumb
                isFirstCrumb
                to={`${window.location.origin}${HELP_CENTERS_MANAGEMENT_BASE_PATH}`}
                name={formatMessage(messages.settingsPageBreadcrumbItem)}
                actionSubjectId="helpCenterBreadcrumb"
            >
                <BreadCrumb to={'/'} name={helpCenterName} actionSubjectId="helpDeskBreadcrumb"></BreadCrumb>
            </BreadCrumb>
        </Box>
    );
};

export default SettingsBreadcrumbs;
