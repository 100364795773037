import React, { useMemo } from 'react';
import { Box, Grid, Stack, xcss, media, Inline, Text } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { LoadingSkeleton } from '@atlassian/help-center-common-component/loading';
import { LinkContainer, ResultList, ResultTitle } from '../common';

export interface SearchResultsLoaderProps {
    numberOfSections: number;
}

export const ArticleResultsLoader = () => {
    const articleItemLoader = useMemo(() => {
        const linkContainer = () => {
            return (
                <Box xcss={headingAreaStyles}>
                    <LoadingSkeleton height={10} backgroundColor={token('color.skeleton.subtle', colors.N20)} />
                </Box>
            );
        };
        const resourceIconContainer = () => {
            return (
                <Box xcss={iconAreaStyles}>
                    <Box xcss={loadingIconStyles}>
                        <LoadingSkeleton height={24} backgroundColor={token('color.skeleton.subtle', colors.N20)} />
                    </Box>
                </Box>
            );
        };
        const metadataContainer = () => {
            return (
                <Box xcss={[subheadingLeftAreaStyles, subheadingStyles]} testId="article-metadata-loader">
                    <Box xcss={metadataLoadingStyles}>
                        <LoadingSkeleton height={10} backgroundColor={token('color.skeleton.subtle', colors.N20)} />
                    </Box>
                </Box>
            );
        };
        const shortcutIconContainer = () => {
            return (
                <Box xcss={[headerRightAreaStylesVisible]} testId="shortcut-icon-loader">
                    <LoadingSkeleton height={20} backgroundColor={token('color.skeleton.subtle', colors.N20)} />
                </Box>
            );
        };
        const descriptionContainer = () => {
            return (
                <Box xcss={bodyAreaStyles}>
                    <Text>
                        <LoadingSkeleton height={20} backgroundColor={token('color.skeleton.subtle', colors.N20)} />
                    </Text>
                </Box>
            );
        };

        return (
            <>
                <Box role="listitem" xcss={containerStyles} testId="article-item-loader">
                    <Grid
                        columnGap="space.150"
                        templateAreas={[
                            'icon heading header-right',
                            'icon subheading-left header-right',
                            '. body body',
                        ]}
                        templateColumns="auto 1fr auto"
                    >
                        {resourceIconContainer()}
                        {linkContainer()}
                        {metadataContainer()}
                        {shortcutIconContainer()}
                        {descriptionContainer()}
                    </Grid>
                </Box>
            </>
        );
    }, []);

    const articlesListLoader = useMemo(() => {
        return (
            <Box xcss={containerStyles} testId="articles-list-loader">
                {articleItemLoader}
                {articleItemLoader}
                {articleItemLoader}
                {articleItemLoader}
            </Box>
        );
    }, [articleItemLoader]);

    const sectionHeadingLoader = () => {
        return (
            <Box>
                <LoadingSkeleton height={20} backgroundColor={token('color.skeleton.subtle', colors.N20)} />
            </Box>
        );
    };

    return (
        <Box>
            {sectionHeadingLoader()}
            {articlesListLoader}
            {articlesListLoader}
        </Box>
    );
};

export const SidePanelResultsLoader = () => {
    const resourceIconContainer = () => {
        return (
            <Box xcss={iconAreaStyles}>
                <Box xcss={sidePanelIconStyles}>
                    <LoadingSkeleton height={24} backgroundColor={token('color.skeleton.subtle', colors.N20)} />
                </Box>
            </Box>
        );
    };
    const descriptionContainer = () => {
        return (
            <Box xcss={sidePanelDescriptionStyles}>
                <Text>
                    <Box xcss={sidePanelDescriptionStyles}>
                        <LoadingSkeleton height={20} backgroundColor={token('color.skeleton.subtle', colors.N20)} />
                    </Box>
                </Text>
            </Box>
        );
    };

    const sidePaneItemLoader = useMemo(() => {
        const linkContainer = () => {
            return (
                <Box xcss={sidePaneLinkContainerStyles}>
                    <Inline xcss={sidePaneLinkStyles} space="space.100">
                        {resourceIconContainer()}
                        {descriptionContainer()}
                    </Inline>
                </Box>
            );
        };
        return <Box xcss={sidePanelItemStyles}>{linkContainer()}</Box>;
    }, []);

    const sidePanelLoader = useMemo(() => {
        const resultTitle = () => (
            <LoadingSkeleton height={20} backgroundColor={token('color.skeleton.subtle', colors.N20)} />
        );
        return (
            <Box xcss={sidePaneContainerStyles}>
                <Box xcss={sidePanelStyles}>
                    {resultTitle()}
                    <ResultList>
                        {sidePaneItemLoader}
                        {sidePaneItemLoader}
                        {sidePaneItemLoader}
                        {sidePaneItemLoader}
                        {sidePaneItemLoader}
                    </ResultList>
                </Box>
            </Box>
        );
    }, [sidePaneItemLoader]);

    return <>{sidePanelLoader}</>;
};

export const NewSearchResultsLoader = () => {
    return (
        <Grid gap="space.400" templateColumns="638px 287px" xcss={responsiveStyles}>
            <Box>
                <ArticleResultsLoader />
            </Box>
            <Stack space="space.100">
                <SidePanelResultsLoader />
                <SidePanelResultsLoader />
            </Stack>
        </Grid>
    );
};

export const SearchResultsLoader = ({ numberOfSections }: SearchResultsLoaderProps) => {
    const resultTitle = useMemo(
        () => (
            <ResultTitle>
                <LoadingSkeleton height={20} backgroundColor={token('color.skeleton.subtle', colors.N20)} />
            </ResultTitle>
        ),
        []
    );
    const linkContainer = useMemo(() => {
        return (
            <LinkContainer actionSubjectId="loading-link-container">
                <Box xcss={searchResultLinkStyles}>
                    <LoadingSkeleton height={65} backgroundColor={token('color.skeleton.subtle', colors.N20)} />
                </Box>
            </LinkContainer>
        );
    }, []);

    // Generate sections based on numberOfSections
    const sections = useMemo(() => {
        const sectionsArray = [];
        for (let i = 0; i < numberOfSections; i++) {
            sectionsArray.push(
                <>
                    {resultTitle}
                    {linkContainer}
                    {linkContainer}
                </>
            );
        }
        return sectionsArray;
    }, [linkContainer, numberOfSections, resultTitle]);

    return <Box testId="search-results-loader">{sections}</Box>;
};

const containerStyles = xcss({
    padding: 'space.100',
    marginLeft: 'space.negative.100',
    marginRight: 'space.negative.100',
    borderRadius: 'border.radius.100',
});
const iconAreaStyles = xcss({
    gridArea: 'icon',
    width: 'size.200',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'right',
});
const headingAreaStyles = xcss({
    width: '200px',
    gridArea: 'heading',
    lineHeight: '20px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    font: token('font.body.large', 'normal'),
});
const subheadingStyles = xcss({
    width: '350px',
    color: 'color.text.subtlest',
    marginTop: 'space.050',
    fontSize: '12px',
    lineHeight: '16px',
    overflow: 'clip',
    overflowClipMargin: '5px',
});
const subheadingLeftAreaStyles = xcss({
    gridArea: 'subheading-left',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    lineHeight: '16px',
});
const headerRightAreaStylesVisible = xcss({
    gridArea: 'header-right',
    display: 'flex',
    opacity: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
});
const responsiveStyles = xcss({
    [media.above.xxs]: { gridTemplateColumns: 'repeat(1, 1fr)' },
    [media.above.md]: {
        gridTemplateColumns: '638px 287px',
    },
});
const bodyAreaStyles = xcss({
    gridArea: 'body',
    marginTop: 'space.100',
    overflowWrap: 'break-word',
});
const loadingIconStyles = xcss({
    width: '100%',
});
const metadataLoadingStyles = xcss({
    width: '100%',
});
const sidePanelIconStyles = xcss({
    width: 'size.200',
});
const sidePanelDescriptionStyles = xcss({
    width: '100%',
});
const sidePaneLinkContainerStyles = xcss({
    width: '100%',
    lineHeight: '20px',
});
const sidePaneLinkStyles = xcss({
    overflow: 'hidden',
    width: '100%',
});
const sidePanelItemStyles = xcss({
    overflow: 'hidden',
    width: '100%',
    padding: 'space.100',
    gap: 'space.150',
    borderRadius: 'border.radius.100',
});
const sidePaneContainerStyles = xcss({
    overflow: 'hidden',
    minWidth: '320px',
    width: '100%',
    paddingTop: 'space.250',
    paddingRight: 'space.150',
    paddingBottom: 'space.150',
    paddingLeft: 'space.150',
    gap: 'space.150',
    borderColor: 'color.border',
    borderStyle: 'solid',
    borderRadius: 'border.radius.100',
    borderWidth: 'border.width',
    rowGap: 'space.100',
});
const sidePanelStyles = xcss({
    width: '100%',
    paddingRight: 'space.100',
    paddingBottom: 'space.050',
    paddingLeft: 'space.100',
});
const searchResultLinkStyles = xcss({
    width: '100%',
    overflow: 'hidden',
    pointerEvents: 'none',
});
