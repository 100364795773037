/**
 * DO NOT EDIT.
 *
 * This is Relay generated artifact.
 *
 * Please reach out to #jsm-cloud-megatron for any questions.
 *
 * @generated SignedSource<<2235b4670dea18c405dbb6bf4fb899cb>>
 * @relayHash b49dd1c3fa4117b725b87c44013238d8
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 871221ea7b287dd154000a8376c0c2d0f5390ee26335752eb6637806f7e00295

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type HelpCenterProjectMappingOperationType = "MAP_PROJECTS" | "UNMAP_PROJECTS" | "%future added value";
export type HelpCenterProjectMappingUpdateInput = {
  helpCenterAri: string;
  operationType?: HelpCenterProjectMappingOperationType | null | undefined;
  projectIds?: ReadonlyArray<string> | null | undefined;
  syncNewProjects?: boolean | null | undefined;
};
export type linkUnlinkProjectMutation$variables = {
  cloudId: string;
  input: HelpCenterProjectMappingUpdateInput;
};
export type linkUnlinkProjectMutation$data = {
  readonly helpCenter: {
    readonly updateProjectMapping: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type linkUnlinkProjectMutation = {
  response: linkUnlinkProjectMutation$data;
  variables: linkUnlinkProjectMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "cloudId",
        "variableName": "cloudId"
      }
    ],
    "concreteType": "HelpCenterMutationApi",
    "kind": "LinkedField",
    "name": "helpCenter",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "HelpCenterProjectMappingUpdatePayload",
        "kind": "LinkedField",
        "name": "updateProjectMapping",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "success",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MutationError",
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "linkUnlinkProjectMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "linkUnlinkProjectMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "871221ea7b287dd154000a8376c0c2d0f5390ee26335752eb6637806f7e00295",
    "metadata": {},
    "name": "linkUnlinkProjectMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "1c658ff020e2feb764137904bff30b9a";

export default node;
