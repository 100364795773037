import * as React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import type { ReqTypesState } from 'state/persisted/portal';
import { AnalyticsContext } from '@atlaskit/analytics-next';
import type { Option as SingleSelectOption } from '@atlaskit/select';
import { SingleSelectField } from '@atlassian/help-center-common-component/fields/single-select-field';
import type { CardLinkAppearance, Option } from '@atlassian/help-center-common-component/group-box-select';
import { GroupBoxSelect } from '@atlassian/help-center-common-component/group-box-select';

export interface RequestTypeSelectProps {
    requestTypes: ReqTypesState[];
    portalId: number | undefined;
    requestGroupId: number | undefined;
    value?: number;
    label: string;
    disabled?: boolean;
    className?: string;
    onChange?: (value?: number) => void;
    autoFocus?: boolean;
    cardLinkAppearance?: CardLinkAppearance;
    isEmbeddedRoute?: boolean;
    isCSM?: boolean;
}

export default class RequestTypeSelect extends React.PureComponent<RequestTypeSelectProps> {
    getOptions(): Option<number>[] {
        const { requestTypes, portalId, requestGroupId, isEmbeddedRoute } = this.props;

        return requestTypes.map((requestType) => ({
            value: Number(requestType.id),
            label: requestType.name,
            description: <RequestTypeDescription dangerouslySetInnerHTML={{ __html: requestType.descriptionHtml }} />,
            iconUrl: requestType.iconUrl,
            // TypeScript upgrade (v4.4.3). Please correct when you revisit this code. Please correct when this code is revisited.
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            to: `/portal/${portalId}/group/${requestGroupId}/create/${requestType.id}`,
            dataTestId: `request-type:${requestType.name}`,
            target: isEmbeddedRoute ? '_blank' : undefined,
        }));
    }

    getCSMOptions(): SingleSelectOption<string>[] {
        const { requestTypes } = this.props;

        return requestTypes.map((requestType) => ({
            value: requestType.name,
            label: requestType.name,
        }));
    }

    getCSMValue(value: number | undefined): SingleSelectOption<string> {
        const { requestTypes } = this.props;

        if (!value) {
            return { value: '', label: '' };
        }

        const requestType = requestTypes.find((requestTypeOption) => Number(requestTypeOption.id) === value);

        return { value: requestType?.name || '', label: requestType?.name || '' };
    }

    render() {
        const { label, onChange, value, disabled, isCSM, ...props } = this.props;
        return (
            <AnalyticsContext
                data={{
                    actionSubjectId: 'requestTypes',
                    attributes: {
                        portalId: props.portalId,
                        requestGroupId: props.requestGroupId,
                        numberOfRequestTypes: props.requestTypes.length,
                    },
                }}
            >
                <>
                    {isCSM ? (
                        <SingleSelectField
                            id="request-type-select"
                            label={label}
                            options={this.getCSMOptions()}
                            defaultValue={this.getCSMValue(value)}
                            disabled={!!disabled}
                        />
                    ) : (
                        <GroupBoxSelect
                            id="request-type-select"
                            actionSubjectId="requestTypeSelect"
                            itemActionSubjectId="requestType"
                            label={label}
                            options={this.getOptions()}
                            onChange={onChange}
                            value={value}
                            disabled={!!disabled}
                            {...props}
                        />
                    )}
                </>
            </AnalyticsContext>
        );
    }
}

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const RequestTypeDescription = styled.span`
    p {
        /* We want any rendered <p>'s to be display inline so it correctly shows ellipsis. */
        display: inline;
    }
`;
