/**
 * DO NOT EDIT.
 *
 * This is Relay generated artifact.
 *
 * Please reach out to #jsm-cloud-megatron for any questions.
 *
 * @generated SignedSource<<8f7ae5b14bfec2599c3cefa489bba7fc>>
 * @relayHash 75738fdd1252461ae14764cade331e73
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 49eb5812f8b2c6df5bd26281ab81db836942b0c3c0e6b5ed0016274a58a80fc6

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type HelpCenterProjectMappingOperationType = "MAP_PROJECTS" | "UNMAP_PROJECTS" | "%future added value";
export type HelpCenterProjectMappingUpdateInput = {
  helpCenterAri: string;
  operationType?: HelpCenterProjectMappingOperationType | null | undefined;
  projectIds?: ReadonlyArray<string> | null | undefined;
  syncNewProjects?: boolean | null | undefined;
};
export type syncProjectsToggleMutation$variables = {
  cloudId: string;
  input: HelpCenterProjectMappingUpdateInput;
};
export type syncProjectsToggleMutation$data = {
  readonly helpCenter: {
    readonly updateProjectMapping: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type syncProjectsToggleMutation = {
  response: syncProjectsToggleMutation$data;
  variables: syncProjectsToggleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "cloudId",
        "variableName": "cloudId"
      }
    ],
    "concreteType": "HelpCenterMutationApi",
    "kind": "LinkedField",
    "name": "helpCenter",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "HelpCenterProjectMappingUpdatePayload",
        "kind": "LinkedField",
        "name": "updateProjectMapping",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "success",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MutationError",
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "syncProjectsToggleMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "syncProjectsToggleMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "49eb5812f8b2c6df5bd26281ab81db836942b0c3c0e6b5ed0016274a58a80fc6",
    "metadata": {},
    "name": "syncProjectsToggleMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "f63e31632e8452ce88ba847207c9b4ff";

export default node;
