/**
 * DO NOT EDIT.
 *
 * This is Relay generated artifact.
 *
 * Please reach out to #jsm-cloud-megatron for any questions.
 *
 * @generated SignedSource<<465f9ccb2b9978de43c9340b1fda4f5e>>
 * @relayHash 1e5f12b66cffa42ce7aedfe5e09a00a5
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 8651fa7c3c3c0b78d3674cff2dcac2d360f07b7fb5d55e8399e28aa90df028e4

import type { ConcreteRequest, Query } from 'relay-runtime';
export type JiraProjectsHelpCenterMappingStatus = "ALL" | "LINKED" | "UNLINKED" | "%future added value";
export type projectsList_GetProjectsQuery$variables = {
  cloudId: string;
  helpCenterId: string;
  helpCenterMappingStatus: JiraProjectsHelpCenterMappingStatus;
};
export type projectsList_GetProjectsQuery$data = {
  readonly jira: {
    readonly jiraProjectsMappedToHelpCenter: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly avatar: {
            readonly small: string | null | undefined;
          } | null | undefined;
          readonly lead: {
            readonly id: string;
            readonly name: string;
            readonly picture: AGG$URL;
          } | null | undefined;
          readonly name: string;
          readonly projectId: string | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type projectsList_GetProjectsQuery = {
  response: projectsList_GetProjectsQuery$data;
  variables: projectsList_GetProjectsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "helpCenterId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "helpCenterMappingStatus"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "helpCenterId",
        "variableName": "helpCenterId"
      },
      {
        "kind": "Variable",
        "name": "helpCenterMappingStatus",
        "variableName": "helpCenterMappingStatus"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "small",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "picture",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "projectsList_GetProjectsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "JiraProjectConnection",
            "kind": "LinkedField",
            "name": "jiraProjectsMappedToHelpCenter",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JiraProjectEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JiraProject",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "lead",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v2/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "projectsList_GetProjectsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "JiraProjectConnection",
            "kind": "LinkedField",
            "name": "jiraProjectsMappedToHelpCenter",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JiraProjectEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JiraProject",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "lead",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          (v5/*: any*/),
                          (v2/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "8651fa7c3c3c0b78d3674cff2dcac2d360f07b7fb5d55e8399e28aa90df028e4",
    "metadata": {},
    "name": "projectsList_GetProjectsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "f2801eadc4540c842edadc98deae9b92";

export default node;
