import { defineMessages } from 'react-intl-next';

export default defineMessages({
    manageProjectsPageTitle: {
        defaultMessage: 'Projects',
        description: 'Help center manage projects page title',
        id: 'manage.projects.page.title.non-final',
    },
    manageProjectsPageDescription: {
        defaultMessage:
            'Manage your help content effectively by linking projects to your help center. This ensures that your customers only see relevant resources in the help center and search. <link>Find out more about linking projects to a help center</link>',
        description: 'Help center manage projects page description',
        id: 'manage.projects.page.description.non-final',
    },
    manageProjectsPageDescriptionForDefaultHC: {
        defaultMessage:
            'All the projects in your site are always linked to your default help center, the resources will always be available in help center portal sections, virtual service agent, search and recently used request forms. <link>Learn more about projects in help centers</link>',
        description: 'Help center manage projects page description',
        id: 'manage.projects.page.default.hc.description.non-final',
    },
    manageProjectsPageDescriptionForDefaultHCOld: {
        defaultMessage:
            'All the projects in your site are always linked to your default help center, the resources will always be available in help center portal sections, virtual agent, search and recently used request forms. <link>Learn more about projects in help centers</link>',
        description: 'Help center manage projects page description',
        id: 'manage.projects.page.default.hc.description-old.non-final',
    },
    manageProjectPageToggleForDefaultLinking: {
        defaultMessage: 'Link all new projects to this help center',
        description: 'Toggle label for default linking',
        id: 'manage.projects.page.toggle.default.linking.non-final',
    },
    linkedFilterLabel: {
        defaultMessage: 'Linked projects',
        description: 'Label for linked dropdown item',
        id: 'manage.projects.page.linked.dropdown.item.label',
    },
    unlinkedFilterLabel: {
        defaultMessage: 'Unlinked projects',
        description: 'Label for unlinked dropdown item',
        id: 'manage.projects.page.unlinked.dropdown.item.label',
    },
    searchProjectsPlaceholder: {
        defaultMessage: 'Search for projects',
        description: 'Placeholder for search projects input',
        id: 'manage.projects.page.search.placeholder.non-final',
    },
});
