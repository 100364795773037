import type { PropsWithChildren } from 'react';
import React from 'react';
import { getJsmAIConfigEnabled } from '@helpCenter/util/env';
import ErrorBoundary from '@helpCenter/view/error-boundary/error-boundary';
import { NewGlobalBGColor } from '@helpCenter/view/styles/global';
import ErrorScreen from 'assets/images/project-mapping-error.svg';
import {
    isProjectMappingInHelpCenterEnabled,
    isVaRebrandToVsaEnabled,
    isJsmVirtualAgentOnHelpcenterEnabled,
} from 'feature-flags';
import { useIntl } from 'react-intl-next';
import { di } from 'react-magnetic-di';
import { useRouter } from 'react-resource-router';
import { useMediaQuery } from 'view/help-center/use-screen-size/use-screen-size';
import * as grid from 'view/styles/grid';
import { sizes } from 'view/styles/sizes-viewport';
import EmptyState from '@atlaskit/empty-state';
import AiChatIcon from '@atlaskit/icon/core/ai-chat';
import LinkIcon from '@atlaskit/icon/glyph/link';
import PageIcon from '@atlaskit/icon/glyph/page';
import { Main, Content, PageLayout, TopNavigation } from '@atlaskit/page-layout';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { SIDEBAR_WIDTH } from '../constants';
import { Header } from '../header';
import type { NavigationContentItem } from '../sidebar';
import { Sidebar } from '../sidebar';
import messages from './messages';

interface Props {
    featureName: string;
}
export const AdminManagementLayout: React.FC<PropsWithChildren<Props>> = ({ children, featureName }) => {
    di(useRouter, getJsmAIConfigEnabled, isProjectMappingInHelpCenterEnabled);
    const [{ route }, { push }] = useRouter();
    const { formatMessage } = useIntl();
    const { name: routeName } = route;
    const virtualAgentUrl = isJsmVirtualAgentOnHelpcenterEnabled() ? '/virtual-agent' : '/reporting';
    const virtualAgentRouteName = isJsmVirtualAgentOnHelpcenterEnabled() ? 'virtualAgentSettings' : 'reporting';
    const navigationContentItems: NavigationContentItem[] = [
        {
            onClick: () => push('/edit-details') as void,
            text: formatMessage(messages.helpcenterDetailsItem),
            isVisible: isProjectMappingInHelpCenterEnabled(),
            isSelected: routeName === 'edit-details',
            iconBefore: <PageIcon label={formatMessage(messages.helpcenterDetailsItem)} />,
        },
        {
            onClick: () => push('/manage-projects') as void,
            text: formatMessage(messages.helpcenterManageProjectsItem),
            isSelected: routeName === 'manage-projects',
            isVisible: isProjectMappingInHelpCenterEnabled(),
            iconBefore: <LinkIcon label={formatMessage(messages.helpcenterManageProjectsItem)} />,
        },
        {
            onClick: () => push(virtualAgentUrl) as void,
            text: isVaRebrandToVsaEnabled()
                ? formatMessage(messages.helpcenterVsaReportingItem)
                : formatMessage(messages.helpcenterVaReportingItem),
            isSelected: routeName === virtualAgentRouteName,
            isVisible: getJsmAIConfigEnabled(),
            iconBefore: (
                <AiChatIcon
                    label={
                        isVaRebrandToVsaEnabled()
                            ? formatMessage(messages.helpcenterVsaReportingItem)
                            : formatMessage(messages.helpcenterVaReportingItem)
                    }
                />
            ),
        },
    ];
    const renderError = () => {
        return (
            <EmptyState
                header={formatMessage(messages.errorTitle)}
                description={formatMessage(messages.errorDescription)}
                imageUrl={ErrorScreen}
            />
        );
    };

    const packageName = (): string => {
        switch (routeName) {
            case 'edit-details':
                return 'editDetails';
            case 'manage-projects':
                return 'manageProjects';
            case 'virtualAgentSettings':
                return 'virtualAgentSettings';
            case 'reporting':
                return 'reporting';
            default:
                return 'unknown';
        }
    };

    const isLaptop = !useMediaQuery(`(max-width: ${sizes.small}px)`);
    return (
        <Box xcss={pageLayoutWrapper} testId="admin-layout-management-page-wrapper">
            <PageLayout>
                <TopNavigation isFixed={false}>
                    <Header />
                </TopNavigation>
                <Content>
                    {isLaptop && (
                        <Sidebar
                            width={SIDEBAR_WIDTH}
                            navigationContentItems={navigationContentItems}
                            featureName={featureName}
                        />
                    )}
                    <Main>
                        <ErrorBoundary
                            packageName={packageName()}
                            id={'admin-layout-management-page'}
                            renderError={renderError}
                        >
                            <Box xcss={container}>{children}</Box>
                        </ErrorBoundary>
                    </Main>
                    <NewGlobalBGColor />
                </Content>
            </PageLayout>
        </Box>
    );
};

export default AdminManagementLayout;

const container = xcss({
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    maxWidth: grid.multiple(145).px,
    margin: '0 auto',
    paddingBottom: token('space.050', '4px'),
});

const pageLayoutWrapper = xcss({
    overflowAnchor: 'none',
    height: '100%',
});
