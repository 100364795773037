/**
 * DO NOT EDIT.
 *
 * This is Relay generated artifact.
 *
 * Please reach out to #jsm-cloud-megatron for any questions.
 *
 * @generated SignedSource<<28e0d7dd70212a9097a7c5b7919f53e4>>
 * @relayHash 7e893305c1389f0fbf17e833832beb89
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID aa3b29df3d757f8e40a1eb77a9737800534ba043a4267c7a9a527f4d8ec06a10

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type HelpCenterUpdateInput = {
  helpCenterAri: string;
  helpCenterBranding?: HelpCenterBrandingInput | null | undefined;
  name?: HelpCenterNameInput | null | undefined;
  slug?: string | null | undefined;
  virtualAgentEnabled?: boolean | null | undefined;
};
export type HelpCenterNameInput = {
  default: string;
  translations?: ReadonlyArray<HelpCenterTranslationInput | null | undefined> | null | undefined;
};
export type HelpCenterTranslationInput = {
  locale: string;
  localeDisplayName?: string | null | undefined;
  value: string;
};
export type HelpCenterBrandingInput = {
  banner?: HelpCenterBannerInput | null | undefined;
  colors?: HelpCenterBrandingColorsInput | null | undefined;
  homePageTitle?: HelpCenterHomePageTitleInput | null | undefined;
  logo?: HelpCenterLogoInput | null | undefined;
};
export type HelpCenterBrandingColorsInput = {
  bannerTextColor?: string | null | undefined;
  primary?: string | null | undefined;
};
export type HelpCenterLogoInput = {
  fileId?: string | null | undefined;
};
export type HelpCenterBannerInput = {
  filedId?: string | null | undefined;
  useDefaultBanner?: boolean | null | undefined;
};
export type HelpCenterHomePageTitleInput = {
  default: string;
  translations?: ReadonlyArray<HelpCenterTranslationInput | null | undefined> | null | undefined;
};
export type useHelpCenterUpdateMutation$variables = {
  cloudId: string;
  input: HelpCenterUpdateInput;
};
export type useHelpCenterUpdateMutation$data = {
  readonly helpCenter: {
    readonly updateHelpCenter: {
      readonly errors: ReadonlyArray<{
        readonly extensions: {
          readonly statusCode: number | null | undefined;
        } | null | undefined;
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type useHelpCenterUpdateMutation = {
  response: useHelpCenterUpdateMutation$data;
  variables: useHelpCenterUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "statusCode",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useHelpCenterUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "HelpCenterMutationApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "HelpCenterUpdatePayload",
            "kind": "LinkedField",
            "name": "updateHelpCenter",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "MutationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useHelpCenterUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "HelpCenterMutationApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "HelpCenterUpdatePayload",
            "kind": "LinkedField",
            "name": "updateHelpCenter",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "MutationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      (v5/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          }
                        ],
                        "type": "BulkMutationErrorExtension",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "aa3b29df3d757f8e40a1eb77a9737800534ba043a4267c7a9a527f4d8ec06a10",
    "metadata": {},
    "name": "useHelpCenterUpdateMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "162638a77ba5d44dfe258f5e31598716";

export default node;
