import type { ComponentType } from 'react';
import React from 'react';
import { connect } from 'react-redux';
import { showErrorFlag, showSuccessFlag } from '@helpCenter/state/actions/flags';
import { noop } from 'lodash';
import { di } from 'react-magnetic-di';
import { useLazyLoadQuery } from 'react-relay';
import { graphql } from 'relay-runtime';
import { AnalyticsContext, withAnalyticsContext } from '@atlaskit/analytics-next';
import { Box, Grid, Stack } from '@atlaskit/primitives';

import { getWorkspaceAri, getCloudId, getHelpCenterAri } from '@atlassian/help-center-common-util/meta';
import HelpCenterForm from '@atlassian/help-centers-management/src/ui/help-center-form/help-center-form';
import { AdminManagementLayout } from '../admin-management-layout';
import { SettingsBreadcrumbs } from '../settings-breadcrumbs';
import type { editHelpCenter_getHelpcenterById_Query } from './__generated__/editHelpCenter_getHelpcenterById_Query.graphql';

export interface HelpCenterEditDetailsProps {
    showErrorFlag: typeof showErrorFlag;
    showSuccessFlag: typeof showSuccessFlag;
}

const HelpCenterEditDetails: ComponentType<HelpCenterEditDetailsProps> = () => {
    di(AdminManagementLayout);
    const cloudId = getCloudId();

    const data = useLazyLoadQuery<editHelpCenter_getHelpcenterById_Query>(
        graphql`
            query editHelpCenter_getHelpcenterById_Query($helpCenterAri: ID!, $cloudId: ID!) {
                helpCenter(cloudId: $cloudId) @optIn(to: "HelpCenterAggBeta") {
                    helpCenterById(helpCenterAri: $helpCenterAri) {
                        ... on HelpCenter {
                            id
                            name {
                                default
                                translations {
                                    value
                                    locale
                                }
                            }
                            slug @optIn(to: "HelpCenterSlugTest")
                        }
                    }
                }
            }
        `,
        { cloudId, helpCenterAri: getHelpCenterAri() }
    );

    const helpCenter = data.helpCenter?.helpCenterById;
    const { name, slug, id } = helpCenter ?? {};

    return (
        <AdminManagementLayout featureName="Helpcenter settings">
            <AnalyticsContext data={{ attributes: { workspaceAri: getWorkspaceAri() } }}>
                <Grid templateColumns="1fr 2fr 1fr" rowGap="space.400">
                    <Box />
                    <Stack>
                        {name?.default && <SettingsBreadcrumbs helpCenterName={name.default} />}
                        <HelpCenterForm
                            helpCenterToEdit={{
                                translations:
                                    name && name.translations
                                        ? name.translations.map((translation) => ({
                                              value: translation?.value ?? '',
                                              locale: translation?.locale ?? '',
                                          }))
                                        : [],
                                slug: slug ?? '',
                                name: name?.default ?? '',
                                ari: id ?? '',
                            }}
                            isStandaloneForm
                            isOpen
                            onClose={noop}
                        />
                    </Stack>
                    <Box />
                </Grid>
            </AnalyticsContext>
        </AdminManagementLayout>
    );
};

export default connect(null, {
    showErrorFlag,
    showSuccessFlag,
})(withAnalyticsContext({ component: 'helpCenterEditDetails' })(HelpCenterEditDetails));
