import { useRef } from 'react';
import type { FC } from 'react';
import { useRouter } from 'react-resource-router';
import getUFORouteName from '@atlassian/react-ufo/route-name';
import traceUFOPageLoad from '@atlassian/react-ufo/trace-pageload';
import traceUFOTransition from '@atlassian/react-ufo/trace-transition';

export const UFOWatcher: FC = () => {
    const isInitial = useRef(true);
    const [routerState] = useRouter();
    const ufoName = getUFORouteName(routerState.route).toLowerCase();
    const lastUFOName = useRef(ufoName);
    if (ufoName) {
        if (isInitial.current) {
            traceUFOPageLoad(ufoName);
            isInitial.current = false;
        }
        if (lastUFOName.current !== ufoName && !isInitial.current) {
            lastUFOName.current = ufoName;
            traceUFOTransition(ufoName);
        }
    }
    return null;
};
