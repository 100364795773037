/**
 * DO NOT EDIT.
 *
 * This is Relay generated artifact.
 *
 * Please reach out to #jsm-cloud-megatron for any questions.
 *
 * @generated SignedSource<<6071cd792906a5903355b7ba2f3a82d8>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type HelpObjectStoreSearchBackend = "JIRA" | "SEARCH_PLATFORM" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type requestFormsFragment$data = {
  readonly requestForms: ReadonlyArray<{
    readonly __typename: "HelpObjectStoreSearchResult";
    readonly id?: string;
    readonly isExternal?: boolean;
    readonly message?: string | null | undefined;
    readonly searchBackend?: HelpObjectStoreSearchBackend | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"requestFormItemFragment">;
  } | null | undefined>;
  readonly " $fragmentType": "requestFormsFragment";
};
export type requestFormsFragment$key = {
  readonly " $data"?: requestFormsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"requestFormsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "cloudId"
    },
    {
      "kind": "RootArgument",
      "name": "portalIds"
    },
    {
      "kind": "RootArgument",
      "name": "queryTerm"
    },
    {
      "kind": "RootArgument",
      "name": "resultLimit"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "requestFormsFragment",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": "requestForms",
        "args": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "cloudId",
                "variableName": "cloudId"
              },
              {
                "kind": "Literal",
                "name": "entityType",
                "value": "REQUEST_FORM"
              },
              {
                "kind": "Variable",
                "name": "portalIds",
                "variableName": "portalIds"
              },
              {
                "kind": "Variable",
                "name": "queryTerm",
                "variableName": "queryTerm"
              },
              {
                "kind": "Variable",
                "name": "resultLimit",
                "variableName": "resultLimit"
              }
            ],
            "kind": "ObjectValue",
            "name": "searchInput"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "searchHelpObjects",
        "plural": true,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "kind": "RequiredField",
                "field": {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                "action": "THROW",
                "path": "requestForms.__typename"
              },
              {
                "kind": "RequiredField",
                "field": {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                "action": "THROW",
                "path": "requestForms.id"
              },
              {
                "kind": "RequiredField",
                "field": {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isExternal",
                  "storageKey": null
                },
                "action": "THROW",
                "path": "requestForms.isExternal"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "searchBackend",
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "requestFormItemFragment"
              }
            ],
            "type": "HelpObjectStoreSearchResult",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              }
            ],
            "type": "HelpObjectStoreQueryError",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      "action": "THROW",
      "path": "requestForms"
    }
  ],
  "type": "HelpObjectStoreQueryApi",
  "abstractKey": null
};

(node as any).hash = "f015d7806777d8cea7fa9a196109088f";

export default node;
