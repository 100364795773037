import React from 'react';
import { compose } from 'redux';
import { useIntl } from 'react-intl-next';
import { lazyForPaint } from 'react-loosely-lazy';
import { withRouter, type Route } from 'react-resource-router';
import { authPageMessage, callToActionUrlText } from 'view/portal-reorder-experience';
import { AdminManagementLayout } from '@atlassian/admin-layout';
import { withAsync } from '@atlassian/help-center-common-component/with-async';
import { withJsmAiConfigEnabled } from '@atlassian/help-center-common-component/with-jsm-ai-config';
import { withOnlyJiraAdmin } from '@atlassian/help-center-common-component/with-only-jira-admin';
import { withOnlyLoggedIn } from '@atlassian/help-center-common-component/with-only-logged-in';
import { HelpReportingContentGapResource } from '@atlassian/help-center-reporting/src/ui/content-gap-table/resources';
import messages from '@atlassian/help-center-reporting/src/ui/messages';
import { HelpReportingMetricTileResource } from '@atlassian/help-center-reporting/src/ui/metric-grid/resources';
import { HelpCenterVirtualAgentResource } from '@atlassian/help-center-virtual-agent-settings/src/resource';

const AsyncVirtualAgentPage = withAsync({
    loader: lazyForPaint(() =>
        import(
            /* webpackChunkName: "async-help-center-virtual-agent-page" */ '@atlassian/help-center-virtual-agent-settings'
        ).then((VirtualAgentSettingsPage) => VirtualAgentSettingsPage)
    ),
});

const VirtualAgentPage = () => {
    const { formatMessage } = useIntl();
    const WrappedComponent = compose(
        withOnlyLoggedIn({
            redirectUrl: '/',
        }),
        withOnlyJiraAdmin({
            message: formatMessage(authPageMessage),
            callToActionText: formatMessage(callToActionUrlText),
            callToActionUrl: '/portals',
        }),
        withJsmAiConfigEnabled({
            message: formatMessage(messages.aiConfigNotEnabledError),
            callToActionText: formatMessage(callToActionUrlText),
            callToActionUrl: '/portals',
        })
    )(() => (
        <AdminManagementLayout featureName="Virtual Agent Settings">
            <AsyncVirtualAgentPage />
        </AdminManagementLayout>
    ));
    return <WrappedComponent />;
};

export const helpcenterVirtualAgentRoute: Route = {
    name: 'virtualAgentSettings',
    path: '/virtual-agent',
    exact: true,
    resources: [HelpReportingMetricTileResource, HelpReportingContentGapResource, HelpCenterVirtualAgentResource],
    component: withRouter(VirtualAgentPage),
};
