/**
 * DO NOT EDIT.
 *
 * This is Relay generated artifact.
 *
 * Please reach out to #jsm-cloud-megatron for any questions.
 *
 * @generated SignedSource<<4f4697b31d66d5c6befb4175171607cf>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type articleMetadataFragment$data = {
  readonly containerDisplayName: string | null | undefined;
  readonly displayLink: string;
  readonly id: string;
  readonly isExternal: boolean;
  readonly " $fragmentType": "articleMetadataFragment";
};
export type articleMetadataFragment$key = {
  readonly " $data"?: articleMetadataFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"articleMetadataFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "articleMetadataFragment",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      "action": "THROW",
      "path": "id"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "displayLink",
        "storageKey": null
      },
      "action": "THROW",
      "path": "displayLink"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isExternal",
        "storageKey": null
      },
      "action": "THROW",
      "path": "isExternal"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "containerDisplayName",
      "storageKey": null
    }
  ],
  "type": "HelpObjectStoreSearchResult",
  "abstractKey": null
};

(node as any).hash = "243125c226275a9fa5bff8db86192050";

export default node;
