import React, { useEffect } from 'react';
import { isDefaultHelpCenter } from '@helpCenter/util/advanced-help-center';
import ProjectMappingEmpty from 'assets/images/project-mapping-empty.svg';
import EmptySearch from 'assets/images/project-mapping-search-no-match.svg';
import { useIntl } from 'react-intl-next';
import { di } from 'react-magnetic-di';
import type { PreloadedQuery } from 'react-relay';
import { graphql, usePreloadedQuery } from 'react-relay';
import Avatar from '@atlaskit/avatar';
import { Checkbox } from '@atlaskit/checkbox';
import DynamicTable from '@atlaskit/dynamic-table';
import EmptyState from '@atlaskit/empty-state';
import Lozenge from '@atlaskit/lozenge';
import { Box, Inline, Text, xcss } from '@atlaskit/primitives';
import { LinkUnlinkProject } from '../link-unlink-project';
import { ProjectsMappingStatus } from '../types';
import type {
    JiraProjectsHelpCenterMappingStatus,
    projectsList_GetProjectsQuery,
} from './__generated__/projectsList_GetProjectsQuery.graphql';
import { BulkActionToolbar } from './bulk-action-toolbar';
import messages from './messages';

export const FetchProjectsQuery = graphql`
    query projectsList_GetProjectsQuery(
        $cloudId: ID!
        $helpCenterId: ID!
        $helpCenterMappingStatus: JiraProjectsHelpCenterMappingStatus!
    ) {
        jira {
            jiraProjectsMappedToHelpCenter(
                cloudId: $cloudId
                filter: { helpCenterId: $helpCenterId, helpCenterMappingStatus: $helpCenterMappingStatus }
            ) {
                edges {
                    node {
                        name
                        projectId
                        avatar {
                            small
                        }
                        lead {
                            id
                            name
                            picture
                        }
                    }
                }
            }
        }
    }
`;

export interface Props {
    queryReference: PreloadedQuery<projectsList_GetProjectsQuery>;
    searchQuery: string;
    selection: JiraProjectsHelpCenterMappingStatus;
    isReadOnlyView: boolean;
}

interface ProjectSelectionMap {
    [projectId: string]: boolean;
}

export const ROWS_PER_PAGE = 20;
const ProjectsList = ({ queryReference, searchQuery, selection, isReadOnlyView }: Props) => {
    di(isDefaultHelpCenter);
    const [isChecked, setIsChecked] = React.useState(false);
    const [selectedProjectsMap, setSelectedProjectsMap] = React.useState<ProjectSelectionMap>({});
    const selectedProjectsCount = Object.values(selectedProjectsMap).filter(Boolean).length;
    const { formatMessage } = useIntl();
    const data = usePreloadedQuery<projectsList_GetProjectsQuery>(FetchProjectsQuery, queryReference);
    const disableSingleProjectActions = selectedProjectsCount > 0;
    const projects = data.jira?.jiraProjectsMappedToHelpCenter?.edges || [];

    const isIndeterminate = !!(selectedProjectsCount > 0 && selectedProjectsCount < projects.length);

    const filteredProjects = projects.filter((project) =>
        project?.node?.name?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // whenever the selection changes, reset the selected projects
    useEffect(() => {
        setIsChecked(false);
        setSelectedProjectsMap({});
    }, [selection]);

    const onChangeHandler = () => {
        const selectionMap: ProjectSelectionMap = {};
        setSelectedProjectsMap(
            isChecked
                ? selectionMap
                : filteredProjects.reduce((acc, project) => {
                      if (!project?.node?.projectId) {
                          return acc;
                      }
                      acc[project?.node?.projectId] = true;
                      return acc;
                  }, selectionMap)
        );
        setIsChecked(!isChecked);
    };

    const head = {
        cells: [
            ...(isReadOnlyView
                ? []
                : [
                      {
                          key: 'select-all',
                          content: (
                              <Checkbox
                                  aria-checked={isChecked}
                                  isChecked={isChecked}
                                  isIndeterminate={isIndeterminate}
                                  onChange={onChangeHandler}
                                  aria-label={formatMessage(messages.selectAllProjectsCheckboxLabel)}
                                  name="select-all-checkbox"
                              />
                          ),
                          width: 5,
                      },
                  ]),
            {
                key: 'project-name',
                content: 'Project',
                shouldTruncate: true,
                isSortable: true,
                width: 35,
            },
            {
                key: 'lead-name',
                content: 'Lead',
                shouldTruncate: true,
                isSortable: true,
                width: 35,
            },
            ...(isReadOnlyView
                ? []
                : [
                      {
                          key: 'status',
                          content: 'Status',
                          width: 15,
                      },
                      {
                          key: 'action-button',
                          content: 'Actions',
                          width: 15,
                      },
                  ]),
        ],
    };

    const toggleSpaceSelect = (projectId: string) => {
        setSelectedProjectsMap((prevState) => ({
            ...prevState,
            [projectId]: !prevState[projectId],
        }));
    };

    const rows = filteredProjects.map((project) => ({
        key: project?.node?.projectId || project?.node?.name,
        isHighlighted: selectedProjectsMap[project?.node?.projectId || ''],
        cells: [
            ...(isReadOnlyView
                ? []
                : [
                      {
                          content: (
                              <Checkbox
                                  isChecked={!!selectedProjectsMap[project?.node?.projectId || '']}
                                  onChange={() => toggleSpaceSelect(project?.node?.projectId || '')}
                                  name="select-checkbox"
                                  aria-label={'Select project'}
                              />
                          ),
                      },
                  ]),
            {
                key: project?.node?.name,
                content: (
                    <Inline alignBlock="center">
                        <Box paddingBlock="space.050" paddingInlineEnd="space.100">
                            {project?.node?.avatar?.small && (
                                <Avatar
                                    size="small"
                                    appearance="square"
                                    src={project?.node?.avatar?.small}
                                    name={project?.node?.avatar?.small}
                                ></Avatar>
                            )}
                        </Box>
                        <Text>{project?.node?.name}</Text>
                    </Inline>
                ),
            },
            {
                key: project?.node?.lead?.id,
                content: (
                    <Inline alignBlock="center">
                        <Box paddingInlineEnd="space.100">
                            <Avatar
                                size="small"
                                appearance="circle"
                                src={project?.node?.lead?.picture}
                                name={project?.node?.lead?.picture}
                            ></Avatar>
                        </Box>
                        <Text>{project?.node?.lead?.name}</Text>
                    </Inline>
                ),
            },
            ...(isReadOnlyView
                ? []
                : [
                      {
                          content:
                              selection === ProjectsMappingStatus.LINKED ? (
                                  <Lozenge appearance="success">
                                      {formatMessage(messages.projectsListLinkedLozengeLabel)}
                                  </Lozenge>
                              ) : (
                                  <Lozenge>{formatMessage(messages.projectsListUnlinkedLozengeLabel)}</Lozenge>
                              ),
                      },
                      {
                          content: (
                              <LinkUnlinkProject
                                  isDisabled={disableSingleProjectActions}
                                  projectId={project?.node?.projectId || ''}
                                  projectName={project?.node?.name || ''}
                                  selection={selection}
                                  isLastProject={projects.length === 1}
                              />
                          ),
                      },
                  ]),
        ],
    }));

    // If user searches for a project that doesn't exist
    if (rows.length === 0 && searchQuery.trim() !== '') {
        return (
            <EmptyState
                header={formatMessage(messages.projectsListEmptySearchTitle)}
                description={formatMessage(messages.projectsListEmptySearchDescription)}
                imageUrl={EmptySearch}
            />
        );
    }
    // If no projects are linked to the help center
    if (rows.length === 0 && selection === ProjectsMappingStatus.UNLINKED) {
        return (
            <EmptyState
                header={formatMessage(messages.projectsListEmptyStateTitle)}
                description={formatMessage(messages.projectsListEmptyStateDescription)}
                imageUrl={ProjectMappingEmpty}
            />
        );
    }
    return (
        <Box xcss={tableStyles}>
            <BulkActionToolbar selectedProjectsCount={selectedProjectsCount} selection={selection} />
            <DynamicTable
                head={head}
                rows={rows}
                rowsPerPage={ROWS_PER_PAGE}
                defaultPage={1}
                isFixedSize
                defaultSortKey="project-name"
                defaultSortOrder="ASC"
            />
        </Box>
    );
};

const tableStyles = xcss({
    position: 'relative',
    // @ts-expect-error @atlaskit/ui-styling-standard/no-nested-selectors
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
    th: { height: '100%', verticalAlign: 'middle' },
});

export default ProjectsList;
