import React, { useEffect } from 'react';
import { di } from 'react-magnetic-di';
import { withAnalyticsContext } from '@atlaskit/analytics-next';
import { AtlassianNavigation, ProductHome } from '@atlaskit/atlassian-navigation';
import { JiraServiceManagementIcon, JiraServiceManagementLogo } from '@atlaskit/logo';
import { AvatarMenuV2 } from '@atlassian/help-center-avatar-menu';

interface HeaderProps {
    fetchBranding: () => void;
    fetchUser: () => void;
    fetchUserRequestsCounts: () => void;
}

const Home = () => (
    <ProductHome
        icon={JiraServiceManagementIcon}
        logo={JiraServiceManagementLogo}
        href={`${window.location.origin}/jira/your-work`}
    />
);

const Header: React.FC<HeaderProps> = ({ fetchBranding, fetchUser, fetchUserRequestsCounts }: HeaderProps) => {
    di(AvatarMenuV2);

    useEffect(() => {
        fetchBranding();
        fetchUser();
        fetchUserRequestsCounts();
    }, [fetchBranding, fetchUser, fetchUserRequestsCounts]);

    return <AtlassianNavigation label="site" renderProductHome={Home} renderProfile={AvatarMenuV2} primaryItems={[]} />;
};

export default withAnalyticsContext({ component: 'Header' })(Header);
