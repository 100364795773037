import React, { useMemo } from 'react';
import { UFOWatcher } from 'util/ufo-watcher';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled
import { ThemeProvider } from 'styled-components';
import { isMultiHcKoshIntegrationEnabled } from 'feature-flags';
import { RouteComponent, Router } from 'react-resource-router';
import { viewHelpCentersManagementPageRoute } from 'routes/help-centers-management';
import { getHelpCenterTitleColor, getThemeColor } from 'state/selectors/help-center';
import { lightenColor, readableTextColor } from 'view/styles/colors';
import { GlobalStyled } from 'view/styles/global';
import { history } from '@atlassian/help-center-common-util/history';
import { resourcesPlugin } from '../resource-plugin';
import { notFoundErrorRoute } from '../routes';

export interface Props {
    themeColor: string;
    titleColor: string;
}

export const HelpCentersManagementResourceRouter = (props: Props) => {
    //Order matters here
    const routes = useMemo(
        () => [...(isMultiHcKoshIntegrationEnabled() ? [viewHelpCentersManagementPageRoute] : []), notFoundErrorRoute],
        []
    );
    const { themeColor, titleColor } = props;
    const readableTextColorFromTheme = readableTextColor(themeColor);
    const lightColorFromTheme = lightenColor(themeColor);

    const theme = {
        titleColor,
        readableTextColor: readableTextColorFromTheme,
        color: themeColor,
        lightColor: lightColorFromTheme,
    };

    //isGlobal=false, to avoid the router conflicting with the sidebar's router.
    return (
        <ThemeProvider theme={theme}>
            <Router routes={routes} history={history} isGlobal={false} plugins={[resourcesPlugin]}>
                <GlobalStyled />
                {!__SERVER__ ? <UFOWatcher /> : null}
                <RouteComponent />
            </Router>
        </ThemeProvider>
    );
};

const selector = createSelector(getThemeColor, getHelpCenterTitleColor, (themeColor, titleColor) => ({
    themeColor,
    titleColor,
}));

export default connect(selector)(HelpCentersManagementResourceRouter);
